import { Col, Row} from "antd";
import Calltablemain from "./Calltablemain";
import "../CSS/record.scss";
const Callmainbody = () => {
  return (
    <>
      <>
        <Row>
          <Col span={24}>
            <Row className="volvex-chat-header">
              <Col span={24}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-start">
                    <div></div>
                    <div className="volvex-chat-avatar-title">
                      <label className="aivolvex-font">Finance Calls</label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="chatdashboard-record-dection chatbot-dashboard-main-body">
          <Col span={24}>
            <Calltablemain />
          </Col>
        </div>
      </>
    </>
  );
};
export default Callmainbody;
