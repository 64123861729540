import { memo, useEffect, useState } from "react";
import { Col, Row, Button, Drawer, Input, Tooltip, Dropdown } from "antd";
import ChatDesktopBoard from "./Desktop/ChatDesktopBoard";
import ChatMobileboard from "./Mobile/ChatMobileboard";
const ChatsBody = () => {
  return (
    <>
      <div className="conversation-desktop">
        <ChatDesktopBoard />
      </div>
      <div className="conversation-mobile">{/* <ChatMobileboard /> */}</div>
    </>
  );
};
export default ChatsBody;
