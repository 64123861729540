import { memo } from "react";
import LoginBanner from "./LoginBanner";
import "../banners/SignInBanner.scss";
import "./login.scss";
import SignIn from "./SignIn";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";
import { useDispatch } from "react-redux";
import SignUp from "./SignUp";
import MobileSignup from "../Mobile/MobileSignup";
import { welcomeEmail } from "../../components/api/accountAction";
import {
  signUp,
  resendVerificationCode,
  VerificationEamil,
} from "../Amplify/auth-config";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
const Register = () => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);
  const [buttonloader, setButtonLoader] = useState(false);
  const [email, setEmail] = useState("");
  const navigateFn = useNavigate();
  const [registerstatus, setRegisterStatus] = useState(true);
  const [input1, setInput] = useState([]);
  const dispatch = useDispatch();
  const handleSignUpClick = (e) => {
    if (showSignIn) {
      setShowSignUp(true);
      setShowSignIn(false);
      localStorage.setItem("signUpShow", "signUp");
    } else {
      setShowSignUp(false);
      setShowSignIn(true);
      localStorage.setItem("signUpShow", "");
    }
  };
  const onFinishSubmitForm = (val) => {
    //
    setButtonLoader(true);
    const input = {
      firstname: val.firstname,
      lastname: val.lastname,
      email: val.emailid,
      password: val.password,
      company_name: "fivestar",
    };

    setEmail(val.emailid);
    dispatch(signUp(input))
      .unwrap()
      .then((data) => {
        setRegisterStatus(false);
        setButtonLoader(false);
      })
      .catch((err) => {
        message.error(err);

        setButtonLoader(false);
      });
  };
  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject("The two passwords do not match!");
    },
  });

  const validatePassword = ({ getFieldValue }) => ({
    validator(_, value) {
      // if (!value || getFieldValue('password') === value) {
      //   return Promise.reject('Please enter a different password from the previous one.');
      // }

      // Add additional password strength checks here
      if (value.length < 8) {
        return Promise.reject("Password must be at least 8 characters long");
      }

      if (!/[A-Z]/.test(value)) {
        return Promise.reject(
          "Password must contain at least one uppercase letter"
        );
      }

      if (!/[a-z]/.test(value)) {
        return Promise.reject(
          "Password must contain at least one lowercase letter"
        );
      }

      if (!/\d/.test(value)) {
        return Promise.reject("Password must contain at least one digit");
      }

      if (!/[\W_]/.test(value)) {
        return Promise.reject(
          "Password must contain at least one special character"
        );
      }

      return Promise.resolve();
    },
  });

  const onOTPSubmitForm = (val) => {
    setButtonLoader(true);
    var input = {
      email_otp: val.verification_code,
      email: email,
    };
    dispatch(VerificationEamil(input))
      .unwrap()
      .then((data) => {
        message.success("Account Created Successfully");
        setRegisterStatus(true);
        setButtonLoader(false);
        navigateFn("/");
      })
      .catch((err) => {
        setButtonLoader(false);
        message.error(err.message);
      });
  };

  const resetOTP = () => {
    dispatch(resendVerificationCode({ email }))
      .unwrap()
      .then((data) => {
        message.success("Code send successfully");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  return (
    <>
      <div className="main-container d-flex h-screen">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 signin-banner singinbanner">
          <LoginBanner />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
          <div className="container signincontainer">
            {/* <GoogleOAuthProvider
              clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
            > */}
            {/* {showSignIn && <SignIn handleSignUpClick={handleSignUpClick} />} */}
            {/* <SignIn handleSignUpClick={handleSignUpClick} /> */}
            <SignUp
              handleSignUpClick={handleSignUpClick}
              onFinishSubmitForm={onFinishSubmitForm}
              buttonloader={buttonloader}
              setButtonLoader={setButtonLoader}
              email={email}
              setEmail={setEmail}
              registerstatus={registerstatus}
              setRegisterStatus={setRegisterStatus}
              validatePassword={validatePassword}
              validateConfirmPassword={validateConfirmPassword}
              onOTPSubmitForm={onOTPSubmitForm}
              resetOTP={resetOTP}
            />

            {/* {showSignUp && <SignUp handleSignUpClick={handleSignUpClick} />} */}
            {/* </GoogleOAuthProvider> */}
          </div>
        </div>
      </div>
      <div className="mobile-banner banner-bg">
        <MobileSignup
          onFinishSubmitForm={onFinishSubmitForm}
          buttonloader={buttonloader}
          setButtonLoader={setButtonLoader}
          email={email}
          setEmail={setEmail}
          registerstatus={registerstatus}
          setRegisterStatus={setRegisterStatus}
          validatePassword={validatePassword}
          validateConfirmPassword={validateConfirmPassword}
        />
      </div>
    </>
  );
};

export default memo(Register);
