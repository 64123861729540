import React from "react";
import { Row, Col } from "antd";
import { Icon } from "@iconify/react";
import Handoff from "./Handoff";
import { useState } from "react";
const CallTranscript = (props) => {
  console.log(props);
  return (
    <div className="white-font">
      {/* <Col span={24} className="call-analytics-card-header">
        <span> Transcript</span>
        {""}
        <span className="px-1">
          <Icon
            icon="bi:info-circle-fill"
            onClick={() => setCallstate(!callstate)}
          />
        </span>
      </Col> */}
      {props.callstate ? (
        <Col className="p-2 pt-3">{props.records.transcript}</Col>
      ) : (
        <Col className="p-1  pt-3">
          <Handoff record={props.records} />
        </Col>
      )}
    </div>
  );
};

export default CallTranscript;
