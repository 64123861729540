import React from "react";
import {Col } from "antd";
import Handoff from "./Handoff";
const CallTranscript = (props) => {
  return (
    <div className="white-font">
      {props.callstate ? (
        <Col className="p-2 pt-3">{props.records.transcript}</Col>
      ) : (
        <Col className="p-1  pt-3">
          <Handoff record={props.records} />
        </Col>
      )}
    </div>
  );
};

export default CallTranscript;
