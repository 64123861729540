import { Col, Row, Popover } from "antd";
import React from "react";
import CallTranscript from "./CallTranscript";
import CallAnalytics from "./CallAnalytics";
import Callconvo from "./Callconvo";
import { Icon } from "@iconify/react";
import { useState } from "react";
const CallDetails = (props) => {
  const [callstate, setCallstate] = useState(false);
  const content = callstate
    ? "View segmented transcript"
    : "View original transcript";
  return (
    <div className="mx-1 p-1">
      <Row>
        <Col
          span={8}
          className="call-analytics-card p-1 mr-1 call-analytics-card-body"
        >
          {" "}
          <div className="call-analytics-card-header">Details</div>{" "}
          <div className="call-analytics-card-body-content">
            {" "}
            <Callconvo record={props.records} />
          </div>
        </Col>
        <Col span={16} className="p-1 ml-1 call-analytics-card-body">
          <Col span={24} className="call-analytics-card h-50 p-2 ">
            <div className="call-analytics-card-header">
              {" "}
              <span> Transcript</span>
              {""}
              <span className="px-1">
                <Popover content={content} trigger="hover">
                  {" "}
                  <Icon
                    icon="bi:info-circle-fill"
                    onClick={() => setCallstate(!callstate)}
                  />
                </Popover>
              </span>
            </div>
            <div className="call-analytics-card-body-content">
              {" "}
              <CallTranscript records={props.records} callstate={callstate} />
            </div>
          </Col>
          {""}
          <Col span={24} className="call-analytics-card h-50 p-2 mt-1">
            <div className="call-analytics-card-header">
              {" "}
              Speaker Sentiment vs Time
            </div>{" "}
            <div className="call-analytics-card-body-content">
              {" "}
              <CallAnalytics records={props.records} />
            </div>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default CallDetails;
