import React from "react";
import Promptandcountchart from "./Promptandcountchart";
import { Col } from "antd";

const CallAnalytics = (props) => {
  return (
    <div className="container">
      <Col span={24}>
        <Promptandcountchart conversation_id={props.records.conversation_id} />
      </Col>
    </div>
  );
};

export default CallAnalytics;
