import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  getcallsentimentchart,
} from "../../api/accountAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import preloader from "../../../assets/loader/pre_loader.gif";
import { Col } from "antd";
import MainStyle from "../../../MainStyle.scss";

const Promptandcountchart = (props) => {
  const fontcolor = MainStyle["theme-font-primary"];
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([{ name: "Speaker", data: [] }]);
  const { publicdashdate } = useSelector((state) => state.chat);
  const date = new Date(publicdashdate);
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  //
  const chartData = {
    series: data,
    options: {
      chart: {
        height: 350,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      grid: {
        show: true,
        borderColor: "#42445f",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3, 3, 3, 3],
        curve: "straight",
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            fontSize: "7px",
            colors: fontcolor,
          },
        },
      },
      yaxis: {
        max: 10,
        min: 0,
        labels: {
          style: {
            colors: fontcolor,
          },
        },
      },

      plotOptions: {
        area: {
          fillTo: "origin",
          opacity: 1,
        },
      },
      fill: {
        type: "solid",
        // colors: ["#72DDF7", 'transparent'],
        opacity: [0.35, 1],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "right",
        markers: {
          size: 12,
          strokeWidth: 0,
        },
        labels: {
          colors: fontcolor,
        },
      },
    },
  };

  const { id } = useParams();
  const dispatch = useDispatch();

  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);
    if (isNaN(dateObject.getTime())) {
      return "Invalid Date";
    }
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[dateObject.getMonth()];
    const day = ("0" + dateObject.getDate()).slice(-2);
    const formattedDate = `${month}-${day}`;
    return formattedDate;
  }

  useEffect(() => {
    setPreloaderStatus(true);
    dispatch(getcallsentimentchart(props.conversation_id))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.aivolvex_control_plane);
        value.time_scenes ? setCategories(value.time_scenes) : <></>; 
        value.speakers ? setData(value.speakers) : <></>;
        setPreloaderStatus(false);
      })
      .catch((err) => {
        setPreloaderStatus(false);
      });
  }, [props]);
  return (
    <div>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <>
          {" "}
          <Col span={24}>
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={300}
            />
          </Col>
        </>
      )}
    </div>
  );
};

export default Promptandcountchart;
