import { useEffect, useState } from "react";
import { Table, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import {
  getcalltranscript,
  historyrecordviewlist,
} from "../../api/accountAction";
import { Icon } from "@iconify/react";
import preloader from "../../../assets/loader/pre_loader.gif";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import { formatDateHistory } from "../../customhook/CustomHoom";
import MainStyle from "../../../MainStyle.scss";

const Handoff = (props) => {
  const fontcolor = MainStyle["theme-font-primary"];
  const user_session = props.record.conversation_id;
  const dispatch = useDispatch();
  const [tablerecord, setTablerecord] = useState([]);
  const [preloaderstatus, setPreloaderStatus] = useState(false);

  useEffect(() => {
    const input = {
      event_type: "get-conversation",
      user_session: user_session,
    };
    setPreloaderStatus(true);
    dispatch(getcalltranscript(input))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.aivolvex_control_plane);
        const table = value.map((datas) => {
          return {
            speaker: datas.speaker,
            transcription: datas.transcription,
            handed_off: datas.sentiment,
          };
        });
        setPreloaderStatus(false);
        setTablerecord(table);
      })
      .catch((err) => {
        setPreloaderStatus(false);
      });
  }, [user_session]);
  return (
    <>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <>
          <div >
            {tablerecord.map((item, index) => (
              <Row className="history-tab-row">
                <Col span={19}>
                  <Row>
                    <Col span={4} className="puple-font question-font">
                      {item.speaker}:
                    </Col>
                    <Col span={20} className="white-font">
                      {" "}
                      {item.transcription}
                    </Col>
                  </Row>
                </Col>
                <Col span={5}>
                  <Col span={24} className="text-end">
                    {/* {item.handed_off && (<><span>
                                    <Icon icon='mdi:smiley' style={{ color: '#0BB852', fontSize: "24px" }} /></span>{" "}
                                    <span style={{ color: '#0BB852' }} className="smiley-text">{item.handed_off}</span></>)} */}
                    <div>
                      {(() => {
                        let iconColor, iconName, textColor;

                        switch (item.handed_off) {
                          case "Positive":
                            iconColor = "#0BB852";
                            iconName = "mdi:smiley";
                            textColor = "#0BB852";
                            break;
                          case "Neutral":
                            iconColor = "#FFC400";
                            iconName = "ph:smiley-meh-fill";
                            textColor = "#FFC400";
                            break;
                          case "Negative":
                            iconColor = "#FF2441";
                            iconName = "ion:sad";
                            textColor = "#FF2441";
                            break;
                          default:
                            iconColor = fontcolor;
                            iconName = "ph:smiley-fill";
                        }

                        return (
                          <>
                            <span
                              style={{ color: iconColor }}
                              className="smiley-text"
                            >
                              {item.handed_off}
                            </span>
                          </>
                        );
                      })()}
                    </div>
                  </Col>
                </Col>
              </Row>
            ))}
          </div>
        </>
      )}
    </>
  );
};
export default Handoff;
