import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  Card,
  Form,
  Upload,
  Button,
  Select,
  ColorPicker,
  Typography,
  message,
} from "antd";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { insertprofile, listprofile } from "../Auth/Profileauth";
import AWS from "aws-sdk";
import preloader from "../../../assets/loader/pre_loader.gif";
import { imageChecking } from "../../customhook/CustomHoom";
import { profilelistdatakey } from "../../api/accountAction";
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: process.env.REACT_APP_AWS_SIGNATURE_VERSION,
});

const validate_image = {
  beforeUpload: (file) => {
    const isPNG =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/svg+xml";

    const maxSize = 2 * 1024 * 1024;
    const isSizeValid = file.size <= maxSize;

    if (!isPNG) {
      message.error(
        `Oops! It seems you've uploaded an unsupported file type. Please use PNG,JPG or SVG.`
      );
    }

    if (!isSizeValid) {
      message.error(`File size must be less than 5MB.`);
    }
    const return_value = isPNG && isSizeValid ? true : false;
    return return_value || Upload.LIST_IGNORE;
  },
  onChange: (info) => {
    // console.log(info.fileList);
  },
};

const { Title } = Typography;
const Profileform = () => {
  const s3 = new AWS.S3();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedThemeStatus, setSelectedThemeStatus] = useState(false);

  const [oldrecord, setOldrecord] = useState(true);
  const [oldrecordimg, setOldrecordimg] = useState(true);
  const [recordvalue, setRecordvalue] = useState([]);
  const [complogo, setcomplogo] = useState("");
  const [backlogo, setbacklogo] = useState("");
  const [themevalue, setThemevalue] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const [companylogourl, setcompanylogourl] = useState("");
  const [companybgurl, setcompanybgurl] = useState("");
  const [updatelogo, setupdatelogo] = useState(true);
  const [updatebg, setupdatebg] = useState(true);
  const [companyLogo, setCompanyLogo] = useState();
  const [backgroundLogo, setBackgroundLogo] = useState();
  const [isCustomTheme, setIsCustomTheme] = useState();
  const [companyLogoFileList, setCompanyLogoFileList] = useState([]);
  const [backgroundLogoFileList, setBackgroundLogoFileList] = useState([]);
  const [records, setRecords] = useState();
  const [defaulttheme, setDefaultTheme] = useState("default");
  const [defaultthemestatus, setDefaultThemeStatus] = useState(false);
  const [customthemes, setCustomthemes] = useState({
    theme_primary_color: "#1677FF",
    theme_secondary_color: "#1677FF",
    theme_background_color: "#1677FF",
    con_font_color: "#1677FF",
    title_font_color: "#1677FF",
    con_title_and_input_color: "#1677FF",
    font_primary_color: "#1677FF",
    btn_and_active_color: "#1677FF",
    font_secondary_color: "#1677FF",
  });
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const bg = localStorage.getItem("companyloaderimage");
  const customercompanyLogo = localStorage.getItem("companyimage");
  const key_id = "company_info";
  useEffect(() => {
    const input = {
      keyid: "company_info",
      meta_key: "",
      user_id: "",
    };
    listprofile(dispatch, input, setRecordvalue);
  }, []);

  const handleFileUpload = async (file, typeName) => {
    var fileNameWithoutSpaces = file.name.replace(/\s+/g, "-");
    if (typeName == "companylogo") {
      setcompanylogourl(`images/${typeName}/${fileNameWithoutSpaces}`);
      setcomplogo(file);
      setOldrecord(false);
      setupdatelogo(false);
    } else {
      setbacklogo(file);
      setOldrecordimg(false);
      setcompanybgurl(`images/${typeName}/${fileNameWithoutSpaces}`);
      setupdatebg(false);
    }
  };
  const onFinish = async (values) => {
    let logourl = "";
    if (complogo && companylogourl) {
      const params = {
        Bucket: "company-info-bucket",
        Key: companylogourl,
        Body: complogo,
      };
      try {
        const data = await s3.upload(params).promise();
        logourl = data.Location;
      } catch (error) {
        console.error("Error uploading file to S3:", error);

        return;
      }
    }
    let bgurl = "";
    if (backlogo && companybgurl) {
      const params = {
        Bucket: "company-info-bucket",
        Key: companybgurl,
        Body: backlogo,
      };
      try {
        const data = await s3.upload(params).promise();
        bgurl = data.Location;
      } catch (error) {
        console.error("Error uploading file to S3:", error);

        return;
      }
    }
    const inputValues = {
      companyName: values.company_name,
      companylogo: logourl,
      // websiteTheme: values.websitetheme,
      // websiteTheme: themevalue,
      websiteTheme: themevalue == undefined ? records.websitetheme : themevalue,
      primaryColor: customthemes.theme_primary_color,
      secondaryColor: customthemes.theme_secondary_color,
      titlebgvalue: customthemes.con_title_and_input_color,
      titlecolorvalue: customthemes.title_font_color,
      fontColor: customthemes.con_font_color,
      cardColor: customthemes.font_primary_color,
      buttoncolor: customthemes.btn_and_active_color,
      bgcolor: customthemes.theme_background_color,
      sidebarColor: customthemes.font_secondary_color,
      chatBackground: bgurl,
      conversationFontSize: values.conversation_fontsize,
    };

    var inputlist = [
      {
        key_id: key_id,
        meta_key: "display_company_name",
        meta_value: inputValues.companyName,
        user_id: userName,
        type: "text",
      },
      {
        key_id: key_id,
        meta_key: "company_logo",
        meta_value: inputValues.companylogo,
        user_id: userName,
        type: "image",
      },
      {
        key_id: key_id,
        meta_key: "conversation_fontsize",
        meta_value: inputValues.conversationFontSize,
        user_id: userName,
        type: "text",
      },
      {
        key_id: key_id,
        meta_key: "conversation_backgroundimage",
        meta_value: inputValues.chatBackground,
        user_id: userName,
        type: "image",
      },
      {
        key_id: key_id,
        meta_key: "website_theme",
        meta_value: inputValues.websiteTheme,
        user_id: userName,
        type: "text",
      },
    ];
    const final_list = [];
    inputlist.map((data) => {
      if (data.meta_value !== "" || data.meta_value === null) {
        final_list.push(data);
      }
    });
    if (inputValues.websiteTheme == "customtheme") {
      var key_word = "customtheme";
      var inputlistsecondary = [
        {
          key_id: key_word,
          meta_key: "theme_primary_color",
          meta_value: inputValues.primaryColor,
          user_id: userName,
          type: "text",
        },
        // secondarycolor
        {
          key_id: key_word,
          meta_key: "theme_secondary_color",
          meta_value: inputValues.secondaryColor,
          user_id: userName,
          type: "text",
        },
        //setbgcolorValue
        {
          key_id: key_word,
          meta_key: "theme_background_color",
          meta_value: inputValues.bgcolor,
          user_id: userName,
          type: "text",
        },
        //buttoncolor
        {
          key_id: key_word,
          meta_key: "btn_and_active_color",
          meta_value: inputValues.buttoncolor,
          user_id: userName,
          type: "text",
        },
        //fontcolor
        {
          key_id: key_word,
          meta_key: "con_font_color",
          meta_value: inputValues.fontColor,
          user_id: userName,
          type: "text",
        },

        //titlecolorvalue
        {
          key_id: key_word,
          meta_key: "title_font_color",
          meta_value: inputValues.titlecolorvalue,
          user_id: userName,
          type: "text",
        },
        //titlebgvalue
        {
          key_id: key_word,
          meta_key: "con_title_and_input_color",
          meta_value: inputValues.titlebgvalue,
          user_id: userName,
          type: "text",
        },
        //cardColor
        {
          key_id: key_word,
          meta_key: "font_primary_color",
          meta_value: inputValues.cardColor,
          user_id: userName,
          type: "text",
        },
        //sidecolorvalue
        {
          key_id: key_word,
          meta_key: "font_secondary_color",
          meta_value: inputValues.sidebarColor,
          user_id: userName,
          type: "text",
        },
      ];
      const final_list_theme = [];
      inputlistsecondary.map((data) => {
        if (data.meta_value !== "" || data.meta_value === null) {
          final_list_theme.push(data);
        }
      });
      insertprofile(dispatch, final_list_theme);
    }
    insertprofile(dispatch, final_list);
    setupdatelogo(true);
    setupdatebg(true);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  useEffect(() => {
    var isCustomTheme = false;

    if (updatelogo || updatebg) {
      if (recordvalue.length > 0) {
        var records_variable = {
          company_name: "",
          companylogo: "",
          conversation_fontsize: "",
          companylogosub: "",
          websitetheme: "",
        };
        if (recordvalue) {
          recordvalue.forEach((item) => {
            switch (item.meta_key) {
              case "display_company_name":
                records_variable.company_name = item.meta_value;
                break;
              case "company_logo":
                records_variable.companylogo = item.meta_value;
                break;
              case "conversation_fontsize":
                records_variable.conversation_fontsize = String(
                  item.meta_value
                );
                break;
              case "conversation_backgroundimage":
                records_variable.companylogosub = item.meta_value;
                break;
              case "website_theme":
                records_variable.websitetheme = item.meta_value;
                isCustomTheme = records_variable.websitetheme === "customtheme";
                break;
              default:
                break;
            }
          });
        }
        setCompanyLogo(records_variable.companylogo);
        setBackgroundLogo(records_variable.companylogosub);
        setRecords(records_variable);
        setDefaultTheme(records_variable.websitetheme);

        if (records_variable.websitetheme === "customtheme") {
          setSelectedThemeStatus(true);
          const inputid = {
            keyid: "customtheme",
            meta_key: "",
            user_id: "",
          };
          dispatch(profilelistdatakey(inputid))
            .unwrap()
            .then(({ data }) => {
              const theme_responce = JSON.parse(data.aivolvex_control_plane);
              const transformedData = {};
              theme_responce.forEach((item) => {
                transformedData[item.meta_key] = item.meta_value;
              });
              setCustomthemes(transformedData);
              setDefaultThemeStatus(true);
            })
            .catch((err) => {});
        } else {
          setDefaultThemeStatus(true);
        }

        form.setFieldsValue(records_variable);
      }
    }
  }, [recordvalue]);

  useEffect(() => {
    if (isCustomTheme) {
      setSelectedThemeStatus(true);
    }
  }, [isCustomTheme]);

  const handleCopy = () => {
    // Select the input field
    const inputElement = document.getElementById("inputField");
    inputElement.select();
    // Copy the text
    document.execCommand("copy");
    message.success("Copied to clipboard");
  };

  return (
    <>
      {recordvalue.length > 0 ? (
        <>
          <Row className="pro-sub-main-body profile-tab-body">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row className="pink-color">Registration</Row>
              <Row className="ash-color">
                <h6>Your Registration Link</h6>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={17} lg={17} xl={17}>
                  <label className="register-font-color">Register Link</label>
                  <Input
                    id="inputField"
                    className="mt-2 mb-4"
                    value={`${window.location.origin}/signup`}
                    readOnly
                    suffix={
                      <Icon
                        className="register-font-color"
                        icon="solar:copy-broken"
                        onClick={handleCopy}
                        style={{ cursor: "pointer" }}
                      />
                    }
                  />
                  <hr className="white-color" />
                </Col>
              </Row>

              <Row className="pink-color">General</Row>
              <Row className="ash-color">
                <h6>Manage Your Organization</h6>
              </Row>
              <Form
                layout="vertical"
                form={form}
                name="control-ref"
                onFinish={onFinish}
                style={{
                  maxWidth: 800,
                }}
              >
                <Form.Item
                  name="company_name"
                  label="Company Name "
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="companylogo" label="Company Logo">
                  <div className="d-flex justify-content-start">
                    {" "}
                    <Upload
                      {...validate_image}
                      name="avatar"
                      maxCount={1}
                      listType="picture-card"
                      onPreview={onPreview}
                      className="d-flex align-items-center"
                      customRequest={({ file, onSuccess, onError }) => {
                        const typeName = "companylogo";
                        handleFileUpload(file, typeName)
                          .then(() => onSuccess())
                          .catch((error) => {
                            console.error(
                              "Error in custom file upload request:",
                              error
                            );
                            onError(error);
                          });
                      }}
                      onChange={(event) => {
                        setCompanyLogoFileList(event.fileList);
                      }}
                    >
                      {imageChecking(
                        oldrecord,
                        companyLogoFileList.length,
                        companyLogo
                      )}
                    </Upload>
                  </div>
                </Form.Item>
                <hr className="white-color" />
                <Row className="pink-color">Theme</Row>
                <Row className="ash-color">
                  <h6>Manage Your Theme</h6>
                </Row>
                <Form.Item
                  name="conversation_fontsize"
                  label="Conversation fontsize"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a numerical value.",
                      pattern: /^[0-9]+$/,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="companylogosub" label="Background Icon">
                  <div className="d-flex justify-content-start">
                    {" "}
                    <Upload
                      {...validate_image}
                      maxCount={1}
                      name="avatar"
                      listType="picture-card"
                      className="d-flex align-items-center"
                      onPreview={onPreview}
                      customRequest={({ file, onSuccess, onError }) => {
                        const typeName = "backgroundicon";
                        handleFileUpload(file, typeName)
                          .then(() => onSuccess())
                          .catch((error) => {
                            console.error(
                              "Error in custom file upload request:",
                              error
                            );
                            onError(error);
                          });
                      }}
                      onChange={(event) => {
                        setBackgroundLogoFileList(event.fileList);
                      }}
                    >
                      {imageChecking(
                        oldrecordimg,
                        backgroundLogoFileList.length,
                        backgroundLogo
                      )}
                    </Upload>
                  </div>
                </Form.Item>
                <Row>
                  <Title level={5} className="white">
                    Application Theme
                  </Title>
                </Row>
                {defaultthemestatus && (
                  <>
                    <Select
                      defaultValue={defaulttheme}
                      className="profile-theme-select"
                      onChange={(value) => {
                        setThemevalue(value);
                        if (value === "customtheme") {
                          setSelectedThemeStatus(true);
                        } else {
                          setSelectedThemeStatus(false);
                        }
                      }}
                      options={[
                        {
                          value: "default",
                          label: "Default",
                        },
                        {
                          value: "theme1",
                          label: "Theme 1",
                        },
                        {
                          value: "theme2",
                          label: "Theme 2 ",
                        },
                        {
                          value: "customtheme",
                          label: "Custom Theme",
                        },
                      ]}
                    />
                  </>
                )}

                <hr className="white-color" />
                {selectedThemeStatus && defaultthemestatus ? (
                  <>
                    <div>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            name="primarycolor"
                            label="Theme Primary Color "
                          >
                            <div className="d-flex justify-content-start">
                              <Icon
                                icon="carbon:color-palette"
                                className="uploadicon-img icon-size "
                              />
                              <ColorPicker
                                defaultValue={customthemes.theme_primary_color}
                                onChange={(color) => {
                                  const colorCode = color.toHexString();
                                  const updatedData = { ...customthemes };
                                  updatedData.theme_primary_color = colorCode;
                                  setCustomthemes(updatedData);
                                }}
                                size="large"
                                showText
                              />
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="secondarycolor"
                            label="Theme Secondary Color "
                          >
                            {" "}
                            <div className="d-flex justify-content-start">
                              {" "}
                              <Icon
                                icon="carbon:color-palette"
                                className="uploadicon-img icon-size "
                              />
                              <ColorPicker
                                defaultValue={
                                  customthemes.theme_secondary_color
                                }
                                onChange={(color) => {
                                  const colorCode = color.toHexString();
                                  const updatedData = { ...customthemes };
                                  updatedData.theme_secondary_color = colorCode;
                                  setCustomthemes(updatedData);
                                }}
                                size="large"
                                showText
                              />
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            name="fontcolor"
                            label="Conversation Font Color "
                          >
                            <div className="d-flex justify-content-start">
                              <Icon
                                icon="carbon:color-palette"
                                className="uploadicon-img icon-size "
                              />
                              <ColorPicker
                                defaultValue={customthemes.con_font_color}
                                onChange={(color) => {
                                  const colorCode = color.toHexString();
                                  const updatedData = { ...customthemes };
                                  updatedData.con_font_color = colorCode;
                                  setCustomthemes(updatedData);
                                }}
                                size="large"
                                showText
                              />
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="bgcolor"
                            label="Theme Background Color "
                          >
                            <div className="d-flex justify-content-start">
                              <Icon
                                icon="carbon:color-palette"
                                className="uploadicon-img icon-size "
                              />
                              <ColorPicker
                                defaultValue={
                                  customthemes.theme_background_color
                                }
                                onChange={(color) => {
                                  const colorCode = color.toHexString();
                                  const updatedData = { ...customthemes };
                                  updatedData.theme_background_color =
                                    colorCode;
                                  setCustomthemes(updatedData);
                                }}
                                size="large"
                                showText
                              />
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            name="titlecolor"
                            label="Title Font Color "
                          >
                            <div className="d-flex justify-content-start">
                              <Icon
                                icon="carbon:color-palette"
                                className="uploadicon-img icon-size "
                              />
                              <ColorPicker
                                defaultValue={customthemes.title_font_color}
                                onChange={(color) => {
                                  const colorCode = color.toHexString();
                                  const updatedData = { ...customthemes };
                                  updatedData.title_font_color = colorCode;
                                  setCustomthemes(updatedData);
                                }}
                                size="large"
                                showText
                              />
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="titlebackgroundcolor"
                            label="Conversation Title and Input Color "
                          >
                            <div className="d-flex justify-content-start">
                              <Icon
                                icon="carbon:color-palette"
                                className="uploadicon-img icon-size "
                              />
                              <ColorPicker
                                defaultValue={
                                  customthemes.con_title_and_input_color
                                }
                                onChange={(color) => {
                                  const colorCode = color.toHexString();
                                  const updatedData = { ...customthemes };
                                  updatedData.con_title_and_input_color =
                                    colorCode;
                                  setCustomthemes(updatedData);
                                }}
                                size="large"
                                showText
                              />
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            name="cardcolor"
                            label="Font Primary Color "
                          >
                            <div className="d-flex justify-content-start">
                              <Icon
                                icon="carbon:color-palette"
                                className="uploadicon-img icon-size "
                              />
                              <ColorPicker
                                defaultValue={customthemes.font_primary_color}
                                onChange={(color) => {
                                  const colorCode = color.toHexString();
                                  const updatedData = { ...customthemes };
                                  updatedData.font_primary_color = colorCode;
                                  setCustomthemes(updatedData);
                                }}
                                size="large"
                                showText
                              />
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="buttoncolor"
                            label="Button and Active Color "
                          >
                            <div className="d-flex justify-content-start">
                              <Icon
                                icon="carbon:color-palette"
                                className="uploadicon-img icon-size "
                              />
                              <ColorPicker
                                defaultValue={customthemes.btn_and_active_color}
                                onChange={(color) => {
                                  const colorCode = color.toHexString();
                                  const updatedData = { ...customthemes };
                                  updatedData.btn_and_active_color = colorCode;
                                  setCustomthemes(updatedData);
                                }}
                                size="large"
                                showText
                              />
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            name="sidebarcolor"
                            label="Font Secondary Color "
                          >
                            {" "}
                            <div className="d-flex justify-content-start">
                              {" "}
                              <Icon
                                icon="carbon:color-palette"
                                className="uploadicon-img icon-size "
                              />
                              <ColorPicker
                                defaultValue={customthemes.font_secondary_color}
                                onChange={(color) => {
                                  const colorCode = color.toHexString();
                                  const updatedData = { ...customthemes };
                                  updatedData.font_secondary_color = colorCode;
                                  setCustomthemes(updatedData);
                                }}
                                size="large"
                                showText
                              />
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Update Organization
                  </Button>
                </Form.Item>
              </Form>
              {/* </Card> */}
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      )}
    </>
  );
};

export default Profileform;
