import {
  Col,
  Table,
  Tag,
  Space,
  Modal,
  Tooltip,
  Popconfirm,
  Select,
  Input,
  Button,
  Upload,
  Skeleton,
  message,
  Breadcrumb,
} from "antd";
import { memo, useEffect, useState } from "react";
import Historymodal from "./Historymodal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getcalltable,
  triggervoiceanalysis,
  multifileuploads3,
  getcredfileupload,
} from "../../api/accountAction";
import {
  capitalizeWords,
  formatDate,
  generateRandomFourDigitNumber,
} from "../../customhook/CustomHoom";
import { Icon } from "@iconify/react";
import MarkdownToJSX from "markdown-to-jsx";
import preloader from "../../../assets/loader/pre_loader.gif";
import { Row } from "react-bootstrap";
import AWS from "aws-sdk";
import CallDetails from "./CallDetails";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: process.env.REACT_APP_AWS_SIGNATURE_VERSION,
});
const Recordtablemain = (props) => {
  const s3 = new AWS.S3();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState("transcript");
  const [tablerecord, setTablerecord] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [language, setLanguage] = useState(null);
  const [historyopen, setHistoryOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [reloadTable, setReloadTable] = useState(true);
  const [openaudio, setopenaudio] = useState(false);
  const [audio, setaudio] = useState(null);

  const handleCallList = () => {
    setBreadCrumb([
      {
        title: <div onClick={handleCallList}>Call List</div>,
      },
    ]);
    setHistoryOpen(false);
  };

  const [breadCrumb, setBreadCrumb] = useState([
    {
      title: <div onClick={handleCallList}>Call List</div>,
    },
  ]);
  const tablecolumns = [
    {
      title: "Date&Time",
      dataIndex: "datetime",
      key: "datetime",
      align: "center",
      render: (text) => (
        <span style={{ whiteSpace: "nowrap" }}>{formatDate(text)}</span>
      ),
    },
    {
      title: "Sentiment",
      dataIndex: "sentiment",
      key: "sentiment",
      align: "center",
      render: (sentiment, record) => {
        let iconColor, iconName, textColor;
        switch (sentiment) {
          case "Positive":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;
          case "Neutral":
            iconColor = "#FFC400";
            iconName = "ph:smiley-meh-fill";
            textColor = "#FFC400";
            break;
          case "Negative":
            iconColor = "#FF2441";
            iconName = "ion:sad";
            textColor = "#FF2441";
            break;
          default:
            iconColor = "white";
            iconName = "ph:smiley-fill";
        }

        return record.sentiment ? (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<MarkdownToJSX>
                  {record.sentiment_explanation}
                </MarkdownToJSX>
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {sentiment && (
                  <>
                    <span>
                      <Icon
                        icon={iconName}
                        style={{ color: iconColor, fontSize: "24px" }}
                        className="cursor"
                      />
                    </span>
                    <span
                      style={{ color: textColor }}
                      className="smiley-text cursor"
                    >
                      {capitalizeWords(sentiment)}
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        ) : (
          <>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
              }}
            />
          </>
        );
      },
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
      align: "center",
      render: (summary, record) => {
        const truncatedSummary =
          summary?.length > 4 ? summary.slice(0, 5) + "..." : summary;
        return record.summary ? (
          <>
            {summary && (
              <div style={{ whiteSpace: "nowrap" }}>
                <Popconfirm
                  className="record-dashboard-popup"
                  placement="top"
                  title=""
                  description=<MarkdownToJSX>{summary}</MarkdownToJSX>
                  footer={false}
                  okText=""
                  cancelText=""
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                >
                  <span>
                    <Icon
                      icon="fluent:notepad-edit-20-regular"
                      style={{ color: "#fff", fontSize: "24px" }}
                      className="cursor"
                    />
                  </span>{" "}
                  <span>{truncatedSummary}</span>
                </Popconfirm>
              </div>
            )}
          </>
        ) : (
          <>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
              }}
            />
          </>
        );
      },
    },
    {
      title: "Opportunity",
      dataIndex: "lead",
      key: "lead",
      align: "center",
      render: (lead, record) => {
        let iconColor, iconName, textColor;

        switch (lead) {
          case "Yes":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;
          case "Neutral":
            iconColor = "#FFC400";
            iconName = "ph:smiley-meh-fill";
            textColor = "#FFC400";
            break;
          case "No":
            iconColor = "red";
            iconName = "ion:sad";
            textColor = "red";
            break;
          default:
            iconColor = "#fff";
            iconName = "ph:smiley-fill";
        }

        return record.lead ? (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<MarkdownToJSX>
                  {record.lead_explanation}
                </MarkdownToJSX>
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {lead === "Yes" && (
                  <>
                    <span>
                      {" "}
                      <Tag color="green">{capitalizeWords(lead)}</Tag>
                    </span>
                  </>
                )}
                {lead === "No" && (
                  <>
                    <span>
                      {" "}
                      <Tag color="red">{capitalizeWords(lead)}</Tag>
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        ) : (
          <>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
              }}
            />
          </>
        );
      },

      onHeaderCell: () => ({
        style: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          textAlign: "center",
        },
      }),
    },
    {
      title: "Action",
      dataIndex: "action_to_be_taken",
      key: "action_to_be_taken",
      align: "center",
      render: (summary, record) => {
        return record.action_to_be_taken ? (
          <>
            {summary != null && (
              <div style={{ whiteSpace: "nowrap" }}>
                <Popconfirm
                  className="record-dashboard-popup"
                  placement="top"
                  title=""
                  description=<MarkdownToJSX>{summary}</MarkdownToJSX>
                  footer={false}
                  okText=""
                  cancelText=""
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                >
                  <span>
                    <Icon
                      icon="eos-icons:role-binding-outlined"
                      style={{ color: "#fff", fontSize: "24px" }}
                      className="cursor"
                    />
                  </span>
                </Popconfirm>
              </div>
            )}
          </>
        ) : (
          <>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
              }}
            />
          </>
        );
      },
    },
    // {
    //   title: "Audio",
    //   dataIndex: "url",
    //   key: "url",
    //   align: "center",
    //   render: (summary, record) => {
    //     return (
    //       <>
    //         <div style={{ whiteSpace: "nowrap" }}>
    //           <span>
    //             <Icon
    //               icon="teenyicons:audio-document-solid"
    //               style={{ color: "#fff", fontSize: "24px" }}
    //               className="cursor"
    //               onClick={() => {
    //                 setopenaudio(true);
    //                 setaudio(summary);
    //               }}
    //             />
    //           </span>
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text) => capitalizeWords(text),
    },
    {
      title: " ",
      key: "action",
      align: "center",
      render: (text, record, index) =>
        record.status === "completed" ? (
          <Space size="middle">
            <a
              className="view-more"
              onClick={() => handlehistory(record)}
              style={{ whiteSpace: "nowrap", textDecoration: "underline" }}
            >
              View more
            </a>
          </Space>
        ) : (
          <></>
        ),
      onHeaderCell: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  ];

  const items = [
    {
      label: "Date",
      value: "date",
    },
    {
      label: "Agent",
      value: "agent",
    },
    {
      label: "Transcript",
      value: "transcript",
    },
  ];

  let timer;
  const stopTimer = () => {
    clearInterval(timer);
  };

  const handleFileUpload = (event) => {
    const files = selectedFiles.map((fileInfo) => ({
      file: fileInfo,
    }));
    const selectedFile = files;
    // setFileUpload(selectedFile);
    setErrorMessage("");
    handlefileOk(selectedFile);
    setFileModalOpen(false);
    setLanguage(null);
    setSelectedFiles([]);
  };
  // const handlefileOk = (selectedFile) => {
  //   const uniqueID = generateUniqueID();
  //   const params = selectedFile.map((fileDetail) => ({
  //     Bucket: "1ch-cci-audio-input-bucket",
  //     Key: `${uniqueID}_${language}/${fileDetail.file.name}`,
  //     Body: fileDetail.file.originFileObj,
  //   }));
  //   console.log(params);
  //   stopTimer();
  //   handleAWSFileUpload(params, uniqueID);
  // };
  const base64ToArrayBuffer = (base64) => {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const decryptAccessKey = async (encryptedAccessKey, iv, key) => {
    const encryptedAccessKeyBuffer = base64ToArrayBuffer(encryptedAccessKey);
    const ivBuffer = base64ToArrayBuffer(iv);
    const keyBuffer = base64ToArrayBuffer(key);

    const cryptoKey = await window.crypto.subtle.importKey(
      "raw",
      keyBuffer,
      { name: "AES-CBC" },
      false,
      ["decrypt"]
    );

    const decryptedBuffer = await window.crypto.subtle.decrypt(
      {
        name: "AES-CBC",
        iv: ivBuffer,
      },
      cryptoKey,
      encryptedAccessKeyBuffer
    );

    const decoder = new TextDecoder();
    return decoder.decode(decryptedBuffer);
  };
  const handlefileOk = async (selectedFile) => {
    const uniqueID = generateUniqueID();

    const convertFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    };
    try {
      const base64Files = await Promise.all(
        selectedFile.map((fileDetail) =>
          convertFileToBase64(fileDetail.file.originFileObj)
        )
      );
      const params = base64Files.map((base64File, index) => ({
        bucket_name: "1ch-cci-audio-input-bucket",
        file_name: `${uniqueID}_${language}/${selectedFile[index].file.name}`,
        file_content: base64File,
        content_type: "multipart/form-data",
      }));

      stopTimer();
      handleAWSFileUpload(params, uniqueID);
    } catch (error) {
      console.error("Error converting files to base64: ", error);
    }
  };
  const handleAWSFileUpload = async (params, uniqueID) => {
    try {
      const filegroup = { event_type: "file_upload", files: params };

      // Dispatching the multi-file upload action
      const uploadResult = await dispatch(
        multifileuploads3(filegroup)
      ).unwrap();

      // Wait for 2 seconds before starting the analysis
      setTimeout(async () => {
        for (const param of params) {
          try {
            const analysisResult = await dispatch(
              triggervoiceanalysis(param.file_name)
            ).unwrap();
          } catch (analysisError) {
            console.error("Error triggering voice analysis:", analysisError);
          }
        }
        handleTableReload();
        message.success("Analysis Started");
      }, 2000);

      handleTableReload();
    } catch (uploadError) {
      console.error("Error uploading files to S3:", uploadError);
      try {
        const input = { event_type: "largefile_insert" };

        // Dispatching the get credentials action
        const credentialsResult = await dispatch(
          getcredfileupload(input)
        ).unwrap();

        // Parsing credentials
        const record = JSON.parse(credentialsResult.data.key_get_upload);

        const {
          access_key_data: {
            encrypted_key: key_01,
            iv: key_02,
            encryption_key: key_03,
          },
          secret_key_data: {
            encrypted_key: key_11,
            iv: key_12,
            encryption_key: key_13,
          },
        } = record.body;
        const decryptedKey = await decryptAccessKey(key_01, key_02, key_03);
        const decryptedKey2 = await decryptAccessKey(key_11, key_12, key_13);

        // Configuring AWS S3
        const S3_BUCKET = "1ch-cci-audio-input-bucket";
        const REGION = "ap-south-1";
        AWS.config.update({
          accessKeyId: decryptedKey,
          secretAccessKey: decryptedKey2,
          region: REGION,
        });
        const s3 = new AWS.S3();

        // Uploading each file to S3
        for (const file of params) {
          const s3Params = {
            Bucket: S3_BUCKET,
            Key: `${file.file_name}`,
            Body: file.file_content,
            ContentType: file.content_type,
          };
          await s3.putObject(s3Params).promise();
        }

        message.info("Files uploaded successfully.");
        setTimeout(async () => {
          for (const param of params) {
            try {
              const analysisResult = await dispatch(
                triggervoiceanalysis(param.file_name)
              ).unwrap();
            } catch (analysisError) {
              console.error("Error triggering voice analysis:", analysisError);
            }
          }
          handleTableReload();
          message.success("Analysis Started");
        }, 2000);
      } catch (credError) {
        console.error(
          "Error getting credentials or uploading to S3:",
          credError
        );
      }
    }
  };

  // const handleAWSFileUpload = async (params, uniqueID) => {
  //   try {
  //     const filegroup = {
  //       files: params,

  //     };
  //     dispatch(multifileuploads3(filegroup))
  //       .unwrap()
  //       .then(({ data }) => {
  //         console.log(data);
  //       });
  //     setTimeout(() => {
  //       params.map((param) => {
  //         dispatch(triggervoiceanalysis(param.file_name))
  //           .unwrap()
  //           .then(({ data }) => {
  //             console.log(data);
  //           });
  //       });
  //       handleTableReload();
  //       message.success("Analysis Started");
  //     }, 2000);
  //     handleTableReload();
  //   } catch (error) {
  //     console.error("Error uploading files to S3:", error);
  //     try {
  //       const input = {
  //         event_type: "largefile_insert",
  //       };
  //       dispatch(getcredfileupload(input))
  //         .unwrap()
  //         .then(({ data }) => {
  //           console.log(data);
  //           const result = await response.text();
  //           const record = JSON.parse(data);
  //           const key_1 = atob(record.body.x);
  //           const key_2 = atob(record.body.y);
  //           const S3_BUCKET = "1ch-cci-audio-input-bucket";
  //           const REGION = "ap-south-1";
  //           AWS.config.update({
  //             accessKeyId: key_1,
  //             secretAccessKey: key_2,
  //             region: REGION,
  //           });
  //           const s3 = new AWS.S3();
  //           const params = {
  //             Bucket: S3_BUCKET,
  //             Key: `${uniqueID}/_${language}/${file.name}`,
  //             Body: file,
  //           };
  //           const upload = await s3.putObject(params).promise();
  //           message.info("File uploaded successfully.");
  //         });

  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };
  function generateUniqueID() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const randomFourDigitNumber = generateRandomFourDigitNumber();
    return `${year}${month}${day}-${randomFourDigitNumber}`;
  }
  // const handleAWSFileUpload = async (params, uniqueID) => {
  //   try {
  //     const uploadPromises = params.map(async (param) => {
  //       await s3.upload(param).promise();
  //     });
  //     console.log("Files Uploaded");
  //     setTimeout(() => {
  //       console.log("Call voice trigger api");
  //       console.log(params);
  //       params.map((param) => {
  //         dispatch(triggervoiceanalysis(param.Key))
  //           .unwrap()
  //           .then(({ data }) => {
  //             console.log(data);
  //           });
  //       });
  //       handleTableReload();
  //       message.success("Analysis Started");
  //     }, 2000);
  //   } catch (error) {
  //     console.error("Error uploading files to S3:", error);
  //     message.error("Error uploading files to S3:");
  //   }
  // };

  const handlehistory = (record) => {
    setRecords(record);
    setBreadCrumb([
      {
        title: <div onClick={handleCallList}>Call List</div>,
      },
      {
        title: "Call Details",
      },
    ]);
    setHistoryOpen(true);
  };
  const handleCancel = () => {
    setLanguage(null);
    setHistoryOpen(false);
    setSelectedFiles([]);
    setFileModalOpen(false);
    setopenaudio(false);
  };
  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleTableReload = () => {
    setReloadTable(!reloadTable);
  };

  useEffect(() => {
    setPreloaderStatus(true);
    setTablerecord([]);
    dispatch(getcalltable())
      .unwrap()
      .then(({ data }) => {
        // console.log(JSON.parse(data.aivolvex_control_plane));
        const table = JSON.parse(data.aivolvex_control_plane).map((datas) => {
          return {
            lead: datas.oppurtunity,
            sentiment: datas.overall_sentiment,
            datetime: datas.timestamp,
            lead_explanation: datas.lead_explanation,
            sentiment_explanation: datas.sentiment_explanation,
            conversation_id: datas.conversation_id,
            summary: datas.summary,
            transcript: datas.trascribe,
            action_to_be_taken: datas.action_to_be_taken,
            email_content: datas.email_content,
            status: datas.status,
            url: datas.url,
            language: datas.language,
          };
        });
        setTablerecord(table);
        setPreloaderStatus(false);
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });
  }, [props.page, reloadTable]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12} className="d-flex align-items-center">
          <Breadcrumb
            items={breadCrumb}
            separator={<Icon icon="ic:baseline-greater-than" />}
          />
        </Col>
        {historyopen ? (
          <></>
        ) : (
          <Col span={12} className="d-flex justify-content-end">
            <Col
              span={3}
              className="recent-search table-upload"
              onClick={handleTableReload}
            >
              <Icon icon="mdi:refresh" className="table-upload-icon" />
            </Col>
            <Col
              span={3}
              className="recent-search table-upload"
              onClick={() => setFileModalOpen(true)}
              title="Upload Audio File"
            >
              <Icon
                icon="solar:upload-track-broken"
                className="table-upload-icon"
              />
            </Col>
            {/* <Col span={15}>
          <Row className="recent-prompt-search">
            <div className="recent-search">
              <Col>
                <Select
                  className="recent-search-dropdown"
                  onChange={handleItemClick}
                  options={items}
                  defaultValue={selectedItem}
                />
              </Col>
              <Col className="recent-search-input-mobile">
                <Input
                  placeholder="Search"
                  suffix={
                    <Icon
                      icon="material-symbols:search"
                      className="icon-size"
                    />
                  }
                  className="recent-search-input "
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
              </Col>
            </div>
          </Row>
        </Col> */}
            {/* <Col span={3} className="recent-search table-upload" onClick={() => {}}>
          <Icon icon="mdi:filter" className="table-upload-icon" />
        </Col> */}
          </Col>
        )}
      </Row>
      <div>
        {historyopen ? (
          <CallDetails records={records} />
        ) : (
          <Col
            span={24}
            className="dashboard-board-background-table mobile-background mt-2"
          >
            {preloaderstatus ? (
              <>
                <Col span={24}>
                  <div className="aivolvex-sub-loader h-18">
                    <img src={preloader} width="20%" />
                  </div>
                </Col>
              </>
            ) : (
              <Table
                dataSource={tablerecord}
                pagination={{ pageSize: 6 }}
                columns={tablecolumns}
                scroll={{ x: 1500 }}
                className="recent-prompts-table"
              />
            )}
          </Col>
        )}
        {/* <Modal
          open={historyopen}
          footer={false}
          onCancel={handleCancel}
          className="report-table-modal"
          width={1000}
        >
          <Historymodal records={records} />
        </Modal> */}
        <Modal
          open={openaudio}
          footer={false}
          onCancel={handleCancel}
          className="report-table-modal"
          width={700}
        >
          {audio ? (
            <audio
              controls
              controlsList="nodownload"
              style={{
                width: "98%",
                height: "2rem",
              }}
              className="mt-3"
              src={audio}
            />
          ) : (
            <>No audio found</>
          )}
        </Modal>
        <Modal
          // title="Analyse Audio"
          open={fileModalOpen}
          footer={false}
          onCancel={handleCancel}
        >
          <>
            <Upload
              multiple={false}
              fileList={selectedFiles}
              className="upload-file-pdf"
              accept=".wav,.mp3,.mp4,.mpeg,.m4a,.mpga,.webm"
              onChange={(info) => {
                if (
                  info.file.type === "audio/wav" ||
                  info.file.type === "audio/mpeg" ||
                  info.file.type === "audio/mp3" ||
                  info.file.type === "audio/mp4" ||
                  info.file.type === "audio/m4a" ||
                  info.file.type === "audio/mpga" ||
                  info.file.type === "audio/webm" ||
                  info.file.type === "audio/x-m4a" ||
                  info.file.type === "audio/aac" ||
                  info.file.type === "audio/ogg" ||
                  info.file.type === "audio/flac" 
                ) {
                  setSelectedFiles(info.fileList);
                  setErrorMessage("");
                } else {
                  setErrorMessage("Only Audio files are allowed for upload.");
                }
              }}
            >
              <Button className="select-file">
                <Icon icon="line-md:upload-loop" className="upload-file-icon" />
                Select File
              </Button>
              <br />
            </Upload>
            {selectedFiles.length > 0 && (
              <Select
                className="mt-3 mb-3"
                style={{
                  width: "100%",
                }}
                placeholder="Select Language"
                onChange={(value) => setLanguage(value)}
                options={[
                  {
                    value: "thai",
                    label: "Thai",
                  },
                  {
                    value: "tagalog",
                    label: "Tagalog",
                  },
                  {
                    value: "english",
                    label: "English",
                  },
                  {
                    value: "malay",
                    label: "Malay",
                  },
                  {
                    value: "vietnamese",
                    label: "Vietnamese",
                  },
                ]}
              />
            )}
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
            <br />
            {selectedFiles.length > 0 && language && (
              <Button
                onClick={handleFileUpload}
                type="primary"
                className="upload-file "
              >
                {selectedFiles.length > 1 ? "Upload Files" : "Upload File"}
              </Button>
            )}
          </>
        </Modal>
      </div>
    </>
  );
};
export default Recordtablemain;
