import { useParams } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvatarS3DocumentList,
  gettextfile,
  filenameDelete,
} from "../../api/accountAction";
import { Icon } from "@iconify/react";
import {
  Modal,
  Table,
  Input,
  Upload,
  Button,
  message,
  Tag,
  Col,
  Row,
  Popconfirm,
} from "antd";
import { IoTrashBin } from "react-icons/io5";
import {
  formatDate,
  formatFileSize,
  generateTransactionId,
  generateUniqueID,
} from "../../customhook/CustomHoom";
import AWS from "aws-sdk";
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: process.env.REACT_APP_AWS_SIGNATURE_VERSION,
});
const ChatDoc = () => {
  const { avatardetails } = useSelector((state) => state.chat);
  const s3 = new AWS.S3();
  const { chatmessage } = useSelector((state) => state.chat);
  const { id, conversationId } = useParams();
  const [s3filelist, setS3FileList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileupdate, setFileupdate] = useState(false);
  // const [message, setMessage] = useState("");
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [helpkeystatus, setHelpKeyStatus] = useState(false);
  const [fileupload, setFileUpload] = useState();
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sessionid, setSessionID] = useState("");
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const dispatch = useDispatch();
  useEffect(() => {
    let user_id;
    if (avatardetails.avatar_name === "AIVolveX Prometheus") {
      user_id = userName;
    } else {
      user_id = "";
    }
    const input = {
      avatar_id: id,
      company_name: companyName,
      email_id: user_id,
    };
    dispatch(getAvatarS3DocumentList(input))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.aivolvex_control_plane);
        setS3FileList(records);
      })
      .catch((err) => {});
  }, [id, conversationId, chatmessage, fileupdate, updatestatus]);
  const filteredPrompts = s3filelist?.filter((data) =>
    data.file_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleCancel = () => {
    setFileModalOpen(false);
    setSelectedFiles([]);
  };
  const handlefileOk = (selectedFile, chatsessionid) => {
    const uniqueID = generateUniqueID();
    const params = selectedFile.map((fileDetail) => ({
      Bucket: avatardetails.bucket_name,
      Key: `${fileDetail.file.name}`,
      Body: fileDetail.file.originFileObj,
    }));

    handleAWSFileUpload(params, uniqueID, chatsessionid);
  };
  const handleAWSFileUpload = async (params, uniqueID, chatsessionid) => {
    try {
      const uploadPromises = params.map(async (param) => {
        await s3.upload(param).promise();
      });
      setSelectedFiles([]);
      setOpen(false);
      message.info(
        "Your uploaded file is now in the process of analysis. Please be patient and anticipate an email notification. Once received, feel free to initiate your inquiries"
      );
      setTimeout(() => {
        const input = {
          company_name: companyName,
          user_id: userName,
          avatar_id: avatardetails.id,
          user_session: chatsessionid,
        };

        dispatch(gettextfile(input))
          .unwrap()
          .then(({ data }) => {
            const value = JSON.parse(data.aivolvex_control_plane);
            setFileupdate(!fileupdate);
          });
      }, 20000);
    } catch (error) {
      setSelectedFiles([]);
      console.error(error);
    }
  };
  const handlefileremove = (record) => {
    const input = {
      company_name: companyName,
      user_id: userName,
      avatar_id: avatardetails.id,
      unique_id: record.id,
    };
    dispatch(filenameDelete(input))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.aivolvex_control_plane);

        setUpdatestatus(!updatestatus);
      })
      .catch((err) => {});
  };

  const handleFileUpload = (event) => {
    var chatsessionid;
    const files = selectedFiles.map((fileInfo) => ({
      file: fileInfo,
    }));
    const selectedFile = files;
    setFileUpload(selectedFile);
    if (sessionid === "") {
      chatsessionid = generateTransactionId();
      setSessionID(chatsessionid);
    }
    chatsessionid = generateTransactionId();
    handlefileOk(selectedFile, chatsessionid);
    setFileModalOpen(false);
  };
  const columns = [
    {
      title: "Document Name",
      dataIndex: "file_name",
      key: "file_name",
      // width: "40%",
      // width: 120,
      align: "left",
      render: (text, index) => {
        return (
          <>
            <div
              className="cursor-pointer"
              style={{ whiteSpace: "nowrap", overflowWrap: "break-word" }}
              onClick={() => {
                window.open(index.presigned_url, "_blank");
              }}
            >
              {index.file_type === "image/jpeg" ? (
                <Icon
                  icon="flat-color-icons:image-file"
                  style={{ fontSize: "25px", marginRight: "0.7rem" }}
                />
              ) : (
                <Icon
                  icon="vscode-icons:file-type-pdf2"
                  style={{ fontSize: "25px", marginRight: "0.7rem" }}
                />
              )}

              <span>{index.file_name}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "uploaded_datetime",
      key: "uploaded_datetime",
      // width: "20%",
      // width: 120,
      align: "center",
      className: "no-wrap-column",

      render: (date, index) => {
        return (
          <>
            <div style={{ whiteSpace: "nowrap", overflowWrap: "break-word" }}>
              {formatDate(date)}
            </div>
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "file_type",
      key: "file_type",
      // width: "20%",
      // width: 120,
      align: "center",
    },

    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      // width: "10%",
      // width: 150,
      align: "center",
      className: "no-wrap-column",
      render: (value, index) => {
        const fileSize = formatFileSize(value);
        return (
          <div style={{ whiteSpace: "nowrap", overflowWrap: "break-word" }}>
            {fileSize}
          </div>
        );
      },
    },
    {
      title: "Statusupdate",
      dataIndex: "status",
      key: "status",
      // width: "10%",
      // width: 120,
      align: "center",
      render: (_, { status }) => (
        <>
          {status != null && (
            <>
              {status.split(",").map((tag) => {
                let color;
                if (tag.trim() === "completed") {
                  color = "green";
                } else if (tag.trim() === "processing") {
                  color = "orange";
                }
                return (
                  <Tag color={color} key={tag.trim()}>
                    {tag.trim()}
                  </Tag>
                );
              })}
            </>
          )}
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      // width: 120,
      align: "center",
      render: (text, record, index) => (
        <>
          <Popconfirm
            title="Are you sure you want to delete this item?"
            onConfirm={() => handlefileremove(record)}
            placement="right"
            okText="Yes"
            cancelText="No"
          >
            <IoTrashBin className="history-action-icons his-del" />
          </Popconfirm>
        </>
      ),
    },
  ];

  const paginationConfig = {
    pageSize: 5,
  };
  return (
    <>
      <div>
        <div>
          <Row className="d-flex justify-content-between align-items-center">
            {avatardetails.id !== 694 && (
              <>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div
                    className="folder-file-upload"
                    onClick={() => setFileModalOpen(true)}
                  >
                    <label
                      htmlFor="file-input"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <span className="upload-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 35 35"
                          className="upload-mb"
                        >
                          <path d="M9.39779 12.8066H12.9398V23.3141C12.9398 24.3802 13.8042 25.2435 14.8692 25.2435H20.1355C21.2004 25.2435 22.0649 24.3803 22.0649 23.3141V12.8066H25.6057C26.1923 12.8066 26.7196 12.4534 26.9452 11.9113C27.1684 11.3715 27.0442 10.7473 26.6298 10.3329L18.5262 2.22831C17.9602 1.66348 17.0433 1.66348 16.4761 2.22831L8.37391 10.3329C7.95937 10.7473 7.83517 11.3704 8.0596 11.9113C8.28502 12.4524 8.81119 12.8066 9.39779 12.8066Z" />
                          <path d="M33.2597 16.7449C32.2995 16.7449 31.5206 17.5239 31.5206 18.4851V25.7617C31.5206 27.9415 29.7461 29.7148 27.5662 29.7148H7.43365C5.25376 29.7148 3.48049 27.9415 3.48049 25.7617V18.4851C3.48049 17.5239 2.70036 16.7449 1.74024 16.7449C0.778989 16.7449 0 17.5239 0 18.4852V25.7618C0 29.8606 3.33467 33.1954 7.43365 33.1954H27.5662C31.6652 33.1954 35 29.8607 35 25.7618V18.4852C35 17.5239 34.2209 16.7449 33.2597 16.7449Z" />
                        </svg>
                      </span>
                    </label>
                    Upload
                  </div>
                </Col>
              </>
            )}

            {/* <div className="history-search history-chat-document justify-content-end"> */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="history-search history-chat-document d-flex justify-content-end">
                <Input
                  placeholder="Search here"
                  prefix={
                    <Icon
                      icon="material-symbols:search"
                      className="lib-search-icon"
                    />
                  }
                  className="lib-history volvex-search-box"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  bordered={false}
                />
              </div>
            </Col>
            {/* </div> */}
          </Row>
        </div>

        <Table
          className="mt-3 ms-2 table-popup"
          // className="record-dashboard-table"
          dataSource={filteredPrompts}
          columns={columns}
          pagination={paginationConfig}
          style={{ width: "100%", overflow: "auto" }}
        />
      </div>
      <Modal open={fileModalOpen} footer={false} onCancel={handleCancel}>
        <>
          <Upload
            multiple
            fileList={selectedFiles}
            className="upload-file-pdf"
            onChange={(info) => {
              if (info.file.type === "application/pdf") {
                setSelectedFiles(info.fileList);
                setErrorMessage("");
              } else {
                setErrorMessage("Only PDF files are allowed for upload.");
              }
            }}
          >
            <Button className="select-file">
              <Icon icon="line-md:upload-loop" className="upload-file-icon" />
              Select Files
            </Button>
            <br />
          </Upload>
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          <br />
          {selectedFiles.length > 0 && (
            <Button
              onClick={handleFileUpload}
              type="primary"
              className="upload-file "
            >
              {selectedFiles.length > 1 ? "Upload Files" : "Upload File"}
            </Button>
          )}
        </>
      </Modal>
    </>
  );
};
export default ChatDoc;
