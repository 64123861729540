import { memo, useEffect, useState } from "react";
import "./Myprofile.scss";
import { getTokenRemaining, getUserController } from "../api/accountAction";
import { useDispatch, useSelector } from "react-redux";
import SidemenuDesktop from "./SidemenuDesktop";
import { setOfferStatus } from "../Slice/reducer/chatReducer";
import SidemenuMobile from "./SidemenuMobile";
import { message } from "antd";
const Sidemenu = (props) => {
  const [page, setpage] = useState("library");
  const [toggled, setToggled] = useState(false);
  const [avatarhubstatus, setAvatarHubStatus] = useState(false);
  const [offering, setOffering] = useState("");
  const [sidebarmenu, setSidebarMenu] = useState(1);
  const [fullpath, setFullPath] = useState("");
  const dispatch = useDispatch();
  const [token, setToken] = useState(0);
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const { offerstatus } = useSelector((state) => state.chat);
  useEffect(() => {
    setFullPath(window.location.pathname);
    if (window.location.pathname === "/calls") {
      setSidebarMenu(1);
    } else if (window.location.pathname === "/chats") {
      setSidebarMenu(2);
    } else if (window.location.pathname.startsWith("/chats")) {
      setSidebarMenu(2);
    } else if (window.location.pathname === "/finance") {
      setSidebarMenu(3);
    } else if (window.location.pathname.startsWith("/finance")) {
      setSidebarMenu(3);
    }else if (window.location.pathname === "/audit") {
      setSidebarMenu(4);
    }else if (window.location.pathname.startsWith("/audit")) {
      setSidebarMenu(4);
    }
  }, [window.location.pathname, props]);
  useEffect(() => {
    const input = {
      company: companyName,
      user_id: atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
      ),
    };

    dispatch(getUserController(input))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.aivolvex_control_plane);
        setOffering(records.offering);

        if (records.offering == "super admin") {
          dispatch(setOfferStatus(records.offering));
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }, []);
  return (
    <>
      {/* New Desktop side bar */}

      <div className="conversation-desktop">
        <SidemenuDesktop sidebarmenu={sidebarmenu} offering={offering} />
      </div>
      <div className="conversation-mobile">
        <SidemenuMobile sidebarmenu={sidebarmenu} offering={offering} />
      </div>
    </>
  );
};

export default memo(Sidemenu);
