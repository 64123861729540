import {
  Col,
  Table,
  Tag,
  Space,
  Modal,
  Popconfirm,
  Select,
  Button,
  Upload,
  Skeleton,
  message,
  Breadcrumb,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getfinancecalltable,
  triggervoiceanalysis,
} from "../../api/accountAction";
import {
  capitalizeWords,
  formatDate,
  generateRandomFourDigitNumber,
} from "../../customhook/CustomHoom";
import { Icon } from "@iconify/react";
import MarkdownToJSX from "markdown-to-jsx";
import preloader from "../../../assets/loader/pre_loader.gif";
import { Row } from "react-bootstrap";
import AWS from "aws-sdk";
import CallDetails from "./CallDetails";
import PopConfirmQa from "./PopConfirmQa";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: process.env.REACT_APP_AWS_SIGNATURE_VERSION,
});
const Recordtablemain = (props) => {
  const s3 = new AWS.S3();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState("transcript");
  const [tablerecord, setTablerecord] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [language, setLanguage] = useState(null);
  const [historyopen, setHistoryOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [reloadTable, setReloadTable] = useState(true);
  const [openaudio, setopenaudio] = useState(false);
  const [audio, setaudio] = useState(null);

  const handleCallList = () => {
    setBreadCrumb([
      {
        title: <div onClick={handleCallList}>Finance Call List</div>,
      },
    ]);
    setHistoryOpen(false);
  };

  const [breadCrumb, setBreadCrumb] = useState([
    {
      title: <div onClick={handleCallList}>Finance Call List</div>,
    },
  ]);
  const tablecolumns = [
    {
      title: "Date&Time",
      dataIndex: "datetime",
      key: "datetime",
      align: "center",
      render: (text) => (
        <span style={{ whiteSpace: "nowrap" }}>{formatDate(text)}</span>
      ),
    },
    {
      title: "Sentiment",
      dataIndex: "sentiment",
      key: "sentiment",
      align: "center",
      render: (sentiment, record) => {
        let iconColor, iconName, textColor;
        switch (sentiment) {
          case "Positive":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;
          case "Neutral":
            iconColor = "#FFC400";
            iconName = "ph:smiley-meh-fill";
            textColor = "#FFC400";
            break;
          case "Negative":
            iconColor = "#FF2441";
            iconName = "ion:sad";
            textColor = "#FF2441";
            break;
          default:
            iconColor = "white";
            iconName = "ph:smiley-fill";
        }

        return record.sentiment ? (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<MarkdownToJSX>
                  {record.sentiment_explanation}
                </MarkdownToJSX>
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {sentiment && (
                  <>
                    <span>
                      <Icon
                        icon={iconName}
                        style={{ color: iconColor, fontSize: "24px" }}
                        className="cursor"
                      />
                    </span>
                    <span
                      style={{ color: textColor }}
                      className="smiley-text cursor"
                    >
                      {capitalizeWords(sentiment)}
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        ) : (
          <>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
              }}
            />
          </>
        );
      },
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
      align: "center",
      render: (summary, record) => {
        const truncatedSummary =
          summary?.length > 4 ? summary.slice(0, 5) + "..." : summary;
        return record.summary ? (
          <>
            {summary && (
              <div style={{ whiteSpace: "nowrap" }}>
                <Popconfirm
                  className="record-dashboard-popup"
                  placement="top"
                  title=""
                  description=<MarkdownToJSX>{summary}</MarkdownToJSX>
                  footer={false}
                  okText=""
                  cancelText=""
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                >
                  <span>
                    <Icon
                      icon="fluent:notepad-edit-20-regular"
                      style={{ color: "#fff", fontSize: "24px" }}
                      className="cursor"
                    />
                  </span>{" "}
                  <span>{truncatedSummary}</span>
                </Popconfirm>
              </div>
            )}
          </>
        ) : (
          <>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
              }}
            />
          </>
        );
      },
    },
    {
      title: "Opportunity",
      dataIndex: "lead",
      key: "lead",
      align: "center",
      render: (lead, record) => {
        let iconColor, iconName, textColor;

        switch (lead) {
          case "Yes":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;
          case "Neutral":
            iconColor = "#FFC400";
            iconName = "ph:smiley-meh-fill";
            textColor = "#FFC400";
            break;
          case "No":
            iconColor = "red";
            iconName = "ion:sad";
            textColor = "red";
            break;
          default:
            iconColor = "#fff";
            iconName = "ph:smiley-fill";
        }

        return record.lead ? (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<MarkdownToJSX>
                  {record.lead_explanation}
                </MarkdownToJSX>
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {lead === "Yes" && (
                  <>
                    <span>
                      {" "}
                      <Tag color="green">{capitalizeWords(lead)}</Tag>
                    </span>
                  </>
                )}
                {lead === "No" && (
                  <>
                    <span>
                      {" "}
                      <Tag color="red">{capitalizeWords(lead)}</Tag>
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        ) : (
          <>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
              }}
            />
          </>
        );
      },

      onHeaderCell: () => ({
        style: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          textAlign: "center",
        },
      }),
    },
    {
      title: "Action",
      dataIndex: "action_to_be_taken",
      key: "action_to_be_taken",
      align: "center",
      render: (summary, record) => {
        return record.action_to_be_taken ? (
          <>
            {summary != null && (
              <div style={{ whiteSpace: "nowrap" }}>
                <Popconfirm
                  className="record-dashboard-popup"
                  placement="top"
                  title=""
                  description=<MarkdownToJSX>{summary}</MarkdownToJSX>
                  footer={false}
                  okText=""
                  cancelText=""
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                >
                  <span>
                    <Icon
                      icon="eos-icons:role-binding-outlined"
                      style={{ color: "#fff", fontSize: "24px" }}
                      className="cursor"
                    />
                  </span>
                </Popconfirm>
              </div>
            )}
          </>
        ) : (
          <>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
              }}
            />
          </>
        );
      },
    },
    {
      title: "Promise to pay",
      dataIndex: "promise_to_pay",
      key: "promise_to_pay",
      align: "center",
    },
    {
      title: "Money",
      dataIndex: "money",
      key: "money",
      align: "center",
    },
    {
      title: "Audio",
      dataIndex: "url",
      key: "url",
      align: "center",
      render: (summary, record) => {
        return (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <span>
                <Icon
                  icon="teenyicons:audio-document-solid"
                  style={{ color: "#fff", fontSize: "24px" }}
                  className="cursor"
                  onClick={() => {
                    setopenaudio(true);
                    setaudio(summary);
                  }}
                />
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text) => capitalizeWords(text),
    },
    {
      title: "QA Check",
      dataIndex: "qa_status",
      key: "qa_status",
      render: (handed_off, record) => {
        let iconColor, iconName, textColor;
        switch (handed_off) {
          case "Incomplete":
            iconColor = "red";
            iconName = "ion:sad";
            textColor = "red";
            break;
          case "Completed":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;

          default:
            iconColor = "#fff";
            iconName = "ph:smiley-fill";
        }

        return (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<PopConfirmQa content={record.qa_content} />
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {handed_off && (
                  <>
                    <span>
                      <Icon
                        icon={iconName}
                        style={{ color: iconColor, fontSize: "24px" }}
                        className="cursor"
                      />
                    </span>{" "}
                    <span
                      style={{ color: textColor }}
                      className="smiley-text cursor"
                    >
                      {capitalizeWords(handed_off)}
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        );
      },
    },
    {
      title: " ",
      key: "action",
      align: "center",
      render: (text, record, index) =>
        record.status === "completed" ? (
          <Space size="middle">
            <a
              className="view-more"
              onClick={() => handlehistory(record)}
              style={{ whiteSpace: "nowrap", textDecoration: "underline" }}
            >
              View more
            </a>
          </Space>
        ) : (
          <></>
        ),
      onHeaderCell: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  ];

  let timer;
  const stopTimer = () => {
    clearInterval(timer);
  };

  const handleFileUpload = (event) => {
    const files = selectedFiles.map((fileInfo) => ({
      file: fileInfo,
    }));
    const selectedFile = files;
    // setFileUpload(selectedFile);
    setErrorMessage("");
    handlefileOk(selectedFile);
    setFileModalOpen(false);
    setLanguage(null);
    setSelectedFiles([]);
  };
  const handlefileOk = (selectedFile) => {
    const uniqueID = generateUniqueID();
    const params = selectedFile.map((fileDetail) => ({
      Bucket: "1ch-cci-audio-input-bucket",
      Key: `${uniqueID}_${language}/${fileDetail.file.name}`,
      Body: fileDetail.file.originFileObj,
    }));
    stopTimer();
    handleAWSFileUpload(params, uniqueID);
  };
  function generateUniqueID() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const randomFourDigitNumber = generateRandomFourDigitNumber();
    return `${year}${month}${day}-${randomFourDigitNumber}`;
  }
  const handleAWSFileUpload = async (params, uniqueID) => {
    try {
      const uploadPromises = params.map(async (param) => {
        await s3.upload(param).promise();
      });
      setTimeout(() => {
        params.map((param) => {
          dispatch(triggervoiceanalysis(param.Key))
            .unwrap()
            .then(({ data }) => {});
        });
        handleTableReload();
        message.success("Analysis Started");
      }, 2000);
    } catch (error) {
      message.error("Error uploading files to S3:");
    }
  };

  const handlehistory = (record) => {
    setRecords(record);
    setBreadCrumb([
      {
        title: <div onClick={handleCallList}>Finance Call List</div>,
      },
      {
        title: "Finance Call Details",
      },
    ]);
    setHistoryOpen(true);
  };
  const handleCancel = () => {
    setLanguage(null);
    setHistoryOpen(false);
    setSelectedFiles([]);
    setFileModalOpen(false);
    setopenaudio(false);
  };
  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleTableReload = () => {
    setReloadTable(!reloadTable);
  };

  useEffect(() => {
    setPreloaderStatus(true);
    setTablerecord([]);
    dispatch(getfinancecalltable())
      .unwrap()
      .then(({ data }) => {
        const table = JSON.parse(data.aivolvex_control_plane).map((datas) => {
          return {
            lead: datas.oppurtunity,
            sentiment: datas.overall_sentiment,
            datetime: datas.timestamp,
            lead_explanation: datas.lead_explanation,
            sentiment_explanation: datas.sentiment_explanation,
            conversation_id: datas.conversation_id,
            summary: datas.summary,
            transcript: datas.trascribe,
            action_to_be_taken: datas.action_to_be_taken,
            email_content: datas.email_content,
            status: datas.status,
            url: datas.url,
            language: datas.language,
            money: datas.money,
            consent: datas.consent,
            promise_to_pay: datas.promise_to_pay,
            api: datas.api,
            qa_status: datas.qa_status,
            qa_content: datas.qa_content,
          };
        });
        // const filteredList = table.filter((item) => item.lead == "No");
        const filteredList = table.filter((item) => item.api == "finance");
        setTablerecord(filteredList);
        // setTablerecord(table);
        setPreloaderStatus(false);
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });
  }, [props.page, reloadTable]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12} className="d-flex align-items-center">
          <Breadcrumb
            items={breadCrumb}
            separator={<Icon icon="ic:baseline-greater-than" />}
          />
        </Col>
        {historyopen ? (
          <></>
        ) : (
          <Col span={12} className="d-flex justify-content-end">
            <Col
              span={3}
              className="recent-search table-upload"
              onClick={handleTableReload}
            >
              <Icon icon="mdi:refresh" className="table-upload-icon" />
            </Col>
            <Col
              span={3}
              className="recent-search table-upload"
              onClick={() => setFileModalOpen(true)}
              title="Upload Audio File"
            >
              <Icon
                icon="solar:upload-track-broken"
                className="table-upload-icon"
              />
            </Col>
          </Col>
        )}
      </Row>
      <div>
        {historyopen ? (
          <CallDetails records={records} />
        ) : (
          <Col
            span={24}
            className="dashboard-board-background-table mobile-background mt-2"
          >
            {preloaderstatus ? (
              <>
                <Col span={24}>
                  <div className="aivolvex-sub-loader h-18">
                    <img src={preloader} width="20%" />
                  </div>
                </Col>
              </>
            ) : (
              <Table
                dataSource={tablerecord}
                pagination={{ pageSize: 6 }}
                columns={tablecolumns}
                scroll={{ x: 1500 }}
                className="recent-prompts-table"
              />
            )}
          </Col>
        )}
        <Modal
          open={openaudio}
          footer={false}
          onCancel={handleCancel}
          className="report-table-modal"
          width={700}
        >
          {audio ? (
            <audio
              controls
              controlsList="nodownload"
              style={{
                width: "98%",
                height: "2rem",
              }}
              className="mt-3"
              src={audio}
            />
          ) : (
            <>No audio found</>
          )}
        </Modal>
        <Modal open={fileModalOpen} footer={false} onCancel={handleCancel}>
          <>
            <Upload
              multiple={false}
              fileList={selectedFiles}
              className="upload-file-pdf"
              accept=".wav,.mp3,.mp4,.mpeg,.m4a,.mpga,.webm"
              onChange={(info) => {
                if (
                  info.file.type === "audio/wav" ||
                  info.file.type === "audio/mpeg" ||
                  info.file.type === "audio/mp3" ||
                  info.file.type === "audio/mp4" ||
                  info.file.type === "audio/m4a" ||
                  info.file.type === "audio/mpga" ||
                  info.file.type === "audio/webm"
                ) {
                  setSelectedFiles(info.fileList);
                  setErrorMessage("");
                } else {
                  setErrorMessage("Only Audio files are allowed for upload.");
                }
              }}
            >
              <Button className="select-file">
                <Icon icon="line-md:upload-loop" className="upload-file-icon" />
                Select File
              </Button>
              <br />
            </Upload>
            {selectedFiles.length > 0 && (
              <Select
                className="mt-3 mb-3"
                style={{
                  width: "100%",
                }}
                placeholder="Select Language"
                onChange={(value) => setLanguage(value)}
                options={[
                  {
                    value: "thai",
                    label: "Thai",
                  },
                  {
                    value: "tagalog",
                    label: "Tagalog",
                  },
                  {
                    value: "english",
                    label: "English",
                  },
                ]}
              />
            )}
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
            <br />
            {selectedFiles.length > 0 && language && (
              <Button
                onClick={handleFileUpload}
                type="primary"
                className="upload-file "
              >
                {selectedFiles.length > 1 ? "Upload Files" : "Upload File"}
              </Button>
            )}
          </>
        </Modal>
      </div>
    </>
  );
};
export default Recordtablemain;
