import { Col, Row, Tabs } from "antd";
import Handoff from "./Handoff";
import Details from "./Details";
import EmailContent from "./EmailContent";
import Mobilehistorycontent from "../Mobile/Mobilehistorycontent";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Historymodal = (props) => {
  const { avatardetails } = useSelector((state) => state.chat);
  const { id } = useParams();
  const items = [
    {
      key: "1",
      label: "History",
      children: (
        <>
          <div className="public-agent-desktop">
            <Handoff record={props.records} />
          </div>
          <div className="public-agent-mobile">
            <Mobilehistorycontent records={props.records} />
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: "Details",
      children: (
        <Details records={props.records} avatardetails={avatardetails} />
      ),
    },
    {
      key: "3",
      label: "Email Content",
      children: (
        <EmailContent records={props.records} avatardetails={avatardetails} />
      ),
    },
  ];
  return (
    <>
      <Row className="record-history-tab chatbot-dashboard-main-body">
        <Col span={24}>
          <Tabs className="record-tab" defaultActiveKey="1" items={items} />
          {/* <Tabs defaultActiveKey="1" items={items} /> */}
        </Col>
      </Row>
    </>
  );
};
export default Historymodal;
