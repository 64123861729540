import { Row, Col,Popconfirm } from "antd";
import { Icon } from "@iconify/react";
import { capitalizeWords } from "../../customhook/CustomHoom";
import MarkdownToJSX from "markdown-to-jsx";
const Callconvo = (props) => {
  const data = props.record;
  return (
    <>
      <Row>
        <Col span={24} className="pt-4">
          <Row className="p-2">
            <Col span={10} className="puple-font question-font">
              Audio:
            </Col>
            <Col span={14} className="white-font question-font">
              {data.url ? (
                <audio
                  controls
                  controlsList="nodownload"
                  style={{
                    width: "98%",
                    height: "2rem",
                  }}
                  className="mt-2"
                  src={data.url}
                />
              ) : (
                <>No audio found</>
              )}
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={10} className="puple-font question-font">
              Language:
            </Col>
            <Col span={14} className="white-font question-font">
              {capitalizeWords(data.language)}
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={10} className="puple-font question-font">
              Sentiment:
            </Col>
            <Col span={14} className="white-font question-font">
              <Col span={12}>
                <div>
                  {(() => {
                    let iconColor, iconName, textColor;
                    switch (data.sentiment) {
                      case "Positive":
                        iconColor = "#0BB852";
                        iconName = "mdi:smiley";
                        textColor = "#0BB852";
                        break;
                      case "Neutral":
                        iconColor = "#FFC400";
                        iconName = "ph:smiley-meh-fill";
                        textColor = "#FFC400";
                        break;
                      case "negative":
                        iconColor = "#FF2441";
                        iconName = "ion:sad";
                        textColor = "#FF2441";
                        break;
                      default:
                        iconColor = "black";
                        iconName = "ph:smiley-fill";
                    }

                    return (
                      <Popconfirm
                        className="record-dashboard-popup"
                        placement="top"
                        title=""
                        description=<MarkdownToJSX>
                          {data.sentiment_explanation}
                        </MarkdownToJSX>
                        footer={false}
                        okText=""
                        cancelText=""
                        cancelButtonProps={{ style: { display: "none" } }}
                        okButtonProps={{ style: { display: "none" } }}
                      >
                        {data.sentiment && (
                          <>
                            <span className="cursor">
                              <Icon
                                icon={iconName}
                                style={{ color: iconColor, fontSize: "24px" }}
                              />
                            </span>
                            <span
                              style={{ color: textColor }}
                              className="smiley-text cursor"
                            >
                              {capitalizeWords(data.sentiment)}
                            </span>
                          </>
                        )}
                      </Popconfirm>
                    );
                  })()}
                </div>
              </Col>
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={10} className="puple-font question-font">
              Oppurtunity:
            </Col>
            <Col span={12}>
              <div>
                {(() => {
                  let iconColor, iconName, textColor;

                  switch (data.lead) {
                    case "Yes":
                      iconColor = "#0BB852";
                      iconName = "mdi:smiley";
                      textColor = "#0BB852";
                      break;
                    case "Neutral":
                      iconColor = "#FFC400";
                      iconName = "ph:smiley-meh-fill";
                      textColor = "#FFC400";
                      break;
                    case "No":
                      iconColor = "#FF2441";
                      iconName = "ion:sad";
                      textColor = "#FF2441";
                      break;
                    default:
                      iconColor = "black";
                      iconName = "ph:smiley-fill";
                  }

                  return (
                    <Popconfirm
                      className="record-dashboard-popup"
                      placement="top"
                      title=""
                      description=<MarkdownToJSX>
                        {data.lead_explanation}
                      </MarkdownToJSX>
                      footer={false}
                      okText=""
                      cancelText=""
                      cancelButtonProps={{ style: { display: "none" } }}
                      okButtonProps={{ style: { display: "none" } }}
                    >
                      {data.sentiment && (
                        <>
                          <span className="cursor">
                            <Icon
                              icon={iconName}
                              style={{ color: iconColor, fontSize: "24px" }}
                            />
                          </span>
                          <span
                            style={{ color: textColor }}
                            className="smiley-text cursor"
                          >
                            {capitalizeWords(data.lead)}
                          </span>
                        </>
                      )}
                    </Popconfirm>
                  );
                })()}
              </div>
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={10} className="puple-font question-font">
              Action:
            </Col>
            <Col span={14} className="white-font question-font">
              {data.action_to_be_taken}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default Callconvo;
