import "./App.scss";
import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
// import Dashboard from "./components/dashboard/Dashboard";
import Dashboardfile from "./components/DashboardNew/DashboardFile";
import Login from "./components/Login/Login";
import Library from "./components/Library/Library";
import ChartMain from "./components/ChartWindow/ChartMain";
// import History from "./components/History/History";
import History from "./components/History-v2/HistoryBody";
import OnboardAvatar from "./components/Avatar/OnboardAvatar";
import Favorites from "./components/Favorites/Favorites";
// import RecentPrompts from "./components/Recent-Prompts/RecentPrompts";
import RecentPrompts from "./components/Recent-Prompts-v2/RecentPromptsBody";
import AzureAuth from "./components/Azure_auth/AzureAuth";
import Launch from "./components/Launch/Launch";
import Rolemainbody from "./components/Role/Rolemainbody";
import GPT from "./components/gpt/GPT";
import SourcePDFView from "./components/ChartWindow/SourcePDFView";
import MobileSignin from "./components/Mobile/MobileSignin";
import CallsBody from "./components/CallRecords/CallsBody";
import ChatsBody from "./components/ChatRecords/ChatsBody";

//

import { Amplify } from "aws-amplify";

import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
import Register from "./components/Login/Register";
import ConversationBody from "./components/Conversation/ConversationBody";
import Profile from "./components/Profile/Profile";
import Tokenmenu from "./components/Token/Tokenmenu";
import UnderConstruction from "./components/Login/UnderConstruction";

import { profilelistdatakey } from "./components/api/accountAction";
import { useDispatch } from "react-redux";
import preloader from "./assets/loader/pre_loader.gif";
import { Col, Row } from "antd";
import QSearchBar from "./components/QSearchBar/QSearchBar";
import StreamingTest from "./components/customhook/StreamingTest";
import { deleteCookie } from "./components/customhook/CustomHoom";
import CallsBodyV1 from "./components/Callversion1/Desktop/CallsBodyV1";
import CallsBodyV2 from "./components/AuditCall/Desktop/CallsBodyV2";
Amplify.configure(awsExports);

const router = [
  {
    path: "/calls",
    element: <CallsBody />,
  },
  {
    path: "/chats",
    element: <ChatsBody />,
  },
  {
    path: "/finance",
    element: <CallsBodyV1  />,
  },
  {
    path: "/audit",
    element: <CallsBodyV2  />,
  },
  {
    path: "/chats/:id",
    element: <ChatsBody />,
  },
];

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
  }, [location]);
};

function App() {
  const dispatch = useDispatch();
  const [loaderstatus, setLoaderStatus] = useState(false);
  // useEffect(() => {
  //   setLoaderStatus(true);
  //   const inputid = {
  //     keyid: "company_info",
  //     meta_key: "website_theme",
  //     user_id: "",
  //   };
  //   dispatch(profilelistdatakey(inputid))
  //     .unwrap()
  //     .then(({ data }) => {
  //       const responce = JSON.parse(data.aivolvex_control_plane);
  //       if (responce[0].meta_value !== "default") {
  //         const inputid = {
  //           keyid: responce[0].meta_value,
  //           meta_key: "",
  //           user_id: "",
  //         };
  //         dispatch(profilelistdatakey(inputid))
  //           .unwrap()
  //           .then(({ data }) => {
  //             const theme_responce = JSON.parse(data.aivolvex_control_plane);
  //             theme_responce.map((theme_res_data) => {
  //               document.documentElement.style.setProperty(
  //                 "--" + theme_res_data.meta_key,
  //                 theme_res_data.meta_value
  //               );
  //             });
  //             setLoaderStatus(false);
  //           })
  //           .catch((err) => {
  //             setLoaderStatus(false);
  //           });
  //       } else {
  //         setLoaderStatus(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoaderStatus(false);
  //     });

  //   // conversation cookies empty
  //   deleteCookie("conversation_start");
  // }, []);

  const userName = localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    ? atob(localStorage.getItem("AIVolveXIdentityServiceProvider.auth"))
    : "";
  return (
    <div className="App">
      {loaderstatus ? (
        <Row>
          <Col>
            <div className="aivolvex-sub-loader full-wh">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <BrowserRouter>
            <ScrollToTop />
            {/* under construction */}
            {/* <>
          <Routes>
            <Route path="/" element={<UnderConstruction />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </> */}
            {userName ? (
              <>
                <Routes>
                  {router.map((val) => (
                    <Route
                      path={val.path}
                      key={val.path}
                      element={val.element}
                    />
                  ))}
                  <Route path="*" element={<Navigate replace to="/calls" />} />
                </Routes>
              </>
            ) : (
              <>
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/signup" element={<Register />} />
                  <Route path="/azure-auth" element={<AzureAuth />} />
                  <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
              </>
            )}
          </BrowserRouter>
        </>
      )}
    </div>
  );
}

export default App;
