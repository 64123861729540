import { gql } from "@apollo/client";

const ONBOARD_AVATAR = gql`
  query MyQuery(
    $about: String!
    $avatar_title: String!
    $context: String!
    $pod_category: String!
    $avatar_image: String!
    $avatar_owner: String!
    $version: String!
  ) {
    aivolvex_control_plane(
      event_type: "new-avatar"
      company: "cmacgm"
      context: $context
      pod_type: "public"
      about_us: $about
      pod: $pod_category
      avatar_name: $avatar_title
      avatar_image: $avatar_image
      avatar_owner: $avatar_owner
      version: $version
    )
  }
`;

const LIST_AVATAR = gql`
  query MyQuery($company_name: String!, $user_id: String!) {
    aivolvex_control_plane(
      event_type: "get-avatar-details"
      company: $company_name
      user_id: $user_id
    )
  }
`;

const UPDATE_AVATAR = gql`
  query MyQuery(
    $context: String!
    $about_us: String!
    $pod: String!
    $avatar_name: String!
    $avatar_id: String!
    $avatar_image: String!
    $avatar_owner: String!
    $version: String!
  ) {
    aivolvex_control_plane(
      event_type: "update-context"
      company: "cmacgm"
      context: $context
      pod_type: "public"
      about_us: $about_us
      pod: $pod
      avatar_name: $avatar_name
      avatar_id: $avatar_id
      avatar_image: $avatar_image
      avatar_owner: $avatar_owner
      version: $version
    )
  }
`;

const GET_AVATAR_DETAILS = gql`
  query MyQuery($id: String!, $user_id: String!) {
    aivolvex_control_plane(
      event_type: "pod-context-details"
      avatar_id: $id
      user_id: $user_id
    )
  }
`;

const GET_START_CONVERSATION_VERTEX_AI = gql`
  query MyQuery(
    $date_time: String!
    $user_session: String!
    $avatar_id: String!
    $chat: String!
    $ai: String!
    $schema: String!
    $user_name: String!
    $managed_by: String!
    $pod: String!
    $avatar_name: String!
  ) {
    aivolvex_control_plane(
      event_type: "chat"
      company: $schema
      user_id: $user_name
      date_time: $date_time
      user_session: $user_session
      avatar_id: $avatar_id
      chat: $chat
      ai: $ai
      managed_by: $managed_by
      pod: $pod
      avatar_name: $avatar_name
    )
  }
`;

const GET_START_CONVERSATION_OLD = gql`
  query MyQuery(
    $date_time: String!
    $user_session: String!
    $avatar_id: String!
    $chat: String!
    $schema: String!
    $user_name: String!
    $managed_by: String!
  ) {
    open_ai_prompts(
      ai: "openai"
      chat: $chat
      avatar_id: $avatar_id
      company: $schema
      date_time: $date_time
      event_type: "chat"
      managed_by: $managed_by
      user_session: $user_session
      user_id: $user_name
    )
  }
`;

const GET_START_CONVERSATION = gql`
  query MyQuery(
    $user_session: String!
    $avatar_id: String!
    $chat: String!
    $schema: String!
    $user_name: String!
    $avatar_name: String!
    $pod_name: String!
    $temp_session_id: String!
    $streaming_id: String!
    $jwt_token: String!
  ) {
    aivolvex_control_plane(
      event_type: "chat"
      company: $schema
      avatar_id: $avatar_id
      user_session: $user_session
      user_id: $user_name
      chat: $chat
      avatar_name: $avatar_name
      pod_name: $pod_name
      temp_session_id: $temp_session_id
      streaming_id: $streaming_id
      jwt_token: $jwt_token
    )
  }
`;

const GET_START_CONVERSATION_CLOUDSHIFU = gql`
  query MyQuery(
    $date_time: String!
    $user_session: String!
    $chat: String!
    $schema: String!
    $user_name: String!
  ) {
    chatbot_chat(
      ai: "vertexai"
      cloud: "aws"
      date_time: $date_time
      user_session: $user_session
      user_name: $user_name
      schema: $schema
      query: $chat
      event_type: "chat"
    )
  }
`;
const LOGIN_EMAIL_CHECK = gql`
  query MyQuery($user_id: String!) {
    aivolvex_control_plane(
      event_type: "email-id-verification"
      email_id: $user_id
    )
  }
`;

const AZUREADEMAILCHECK = gql`
  query MyQuery($email: String!) {
    company_check(emailid: $email, event_type: "company_check")
  }
`;

const GET_START_CONVERSATION_KENDRA = gql`
  query MyQuery(
    $chat: String!
    $session_id: String!
    $avatar_id: String!
    $customer: String!
    $user_id: String!
  ) {
    query_kendra(
      question: $chat
      user_session: $session_id
      avatar_id: $avatar_id
      customer: $customer
      user_id: $user_id
    )
  }
`;

const GET_START_CONVERSATION_PG_VECTOR = gql`
  query MyQuery(
    $chat: String!
    $session_id: String!
    $avatar_id: String!
    $customer: String!
    $user_id: String!
  ) {
    query_pgvector(
      avatar_id: $avatar_id
      customer: $customer
      user_id: $user_id
      user_session: $session_id
      avatar_type: "pgvector"
      question: $chat
    )
  }
`;

const AVATARFREQUENTLYPROMPTS = gql`
  query MyQuery($id: String!, $user_id: String!, $companyname: String!) {
    aivolvex_control_plane(
      event_type: "frequently_used_prompts"
      user_id: $user_id
      avatar_id: $id
      company: $companyname
    )
  }
`;

const AVATARHISTORYTITLE = gql`
  query MyQuery($id: String!, $user_id: String!, $companyname: String!) {
    aivolvex_control_plane(
      event_type: "get_title_list"
      company: $companyname
      user_id: $user_id
      avatar_id: $id
    )
  }
`;

const AVATARCHATHISTORY = gql`
  query MyQuery($conversationid: String!, $companyname: String!) {
    aivolvex_control_plane(
      event_type: "get_history"
      user_session: $conversationid
      company: $companyname
    )
  }
`;

const CUSTOMERDETAILS = gql`
  query MyQuery($email: String!) {
    aivolvex_control_plane(event_type: "get-customer", user_id: $email)
  }
`;

const GETTOKENREMAINING = gql`
  query MyQuery($company: String!, $avatar_id: String!) {
    aivolvex_control_plane(
      event_type: "get-remaining-credits"
      company: $company
      avatar_id: $avatar_id
    )
  }
`;

const KENDRA_OPENAI = gql`
  query MyQuery(
    $prompts: String!
    $session_id: String!
    $avatar_id: String!
    $customer: String!
    $user_id: String!
    $question: String!
  ) {
    openai_response(
      prompt: $prompts
      user_session: $session_id
      avatar_id: $avatar_id
      customer: $customer
      user_id: $user_id
      question: $question
    )
  }
`;

const GET_START_CONVERSATION_GENERAL_CHAT = gql`
  query MyQuery(
    $chat: String!
    $session_id: String!
    $avatar_id: String!
    $customer: String!
    $user_id: String!
    $date_time: String!
    $managed_by: String!
  ) {
    query_titan(
      avatar_id: $avatar_id
      avatar_type: "general"
      customer: $customer
      question: $chat
      user_id: $user_id
      user_session: $session_id
      managed_by: $managed_by
      event_type: "chat"
      date_time: $date_time
    )
  }
`;

const AVATAR_TAG_MEMBERS_LIST = gql`
  query MyQuery(
    $event_type: String!
    $company: String!
    $share_type: String!
    $user_id: String!
    $avatar_id: String!
  ) {
    aivolvex_control_plane(
      event_type: $event_type
      company: $company
      user_id: $user_id
      share_type: $share_type
      avatar_id: $avatar_id
    )
  }
`;

const SHARE_NEW_AVATRA = gql`
  query MyQuery(
    $share_type: String!
    $user_id: String!
    $user_session: String!
    $selected_id: String!
    $title: String!
    $company: String!
    $avatar_id: String!
  ) {
    aivolvex_control_plane(
      event_type: "insert-share-conversation"
      share_type: $share_type
      user_id: $user_id
      user_session: $user_session
      selected_id: $selected_id
      company: $company
      avatar_id: $avatar_id
      title: $title
    )
  }
`;

const INSERT_NEW_FEEDBACK = gql`
  query MyQuery(
    $rating: String!
    $feedback: String!
    $avatar_id: String!
    $user_id: String!
    $question_id: String!
  ) {
    aivolvex_control_plane(
      event_type: "feedback_insert"
      agent_id: $avatar_id
      user_id: $user_id
      question_id: $question_id
      rating: $rating
      feedback_content: $feedback
    )
  }
`;

const GET_AVATAR_LIBRARY_LIST = gql`
  query MyQuery($company_name: String!, $id: String!) {
    aivolvex_control_plane(
      event_type: "avatar_library"
      company: $company_name
      avatar_id: $id
    )
  }
`;

const GET_TAG_LIST = gql`
  query MyQuery($compnayname: String!) {
    aivolvex_control_plane(company: $compnayname, event_type: "tag_list")
  }
`;

const INSERT_NEW_TAG = gql`
  query MyQuery(
    $company: String!
    $user_id: String!
    $tag_title: String!
    $members_list: String!
    $avatar_list: String!
  ) {
    aivolvex_control_plane(
      event_type: "tag_insert"
      company: $company
      user_id: $user_id
      tag_title: $tag_title
      members_list: $members_list
      avatar_list: $avatar_list
    )
  }
`;

const UPDATE_TAG = gql`
  query MyQuery(
    $id: String!
    $company: String!
    $user_id: String!
    $tag_title: String!
    $members_list: String!
    $avatar_list: String!
    $user_type: String!
  ) {
    aivolvex_control_plane(
      event_type: "update-tag-details"
      id: $id
      company: $company
      user_id: $user_id
      tag_title: $tag_title
      members_list: $members_list
      avatar_list: $avatar_list
      user_type: $user_type
    )
  }
`;

const DELETE_TAG = gql`
  query MyQuery($id: String!, $company: String!) {
    aivolvex_control_plane(event_type: "delete-tag", id: $id, company: $company)
  }
`;

const GET_AVALATAR_LIST = gql`
  query MyQuery($company: String!) {
    aivolvex_control_plane(event_type: "avatar_list", company: $company)
  }
`;
const HISTORY_EDIT = gql`
  query MyQuery(
    $user_id: String!
    $user_session: String!
    $avatar_id: String!
    $company: String!
    $title: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      user_session: $user_session
      event_type: "update-title-list"
      avatar_id: $avatar_id
      company: $company
      title: $title
    )
  }
`;

const HISTORY_DELETE = gql`
  query MyQuery($user_id: String!, $user_session: String!, $company: String!) {
    aivolvex_control_plane(
      user_id: $user_id
      user_session: $user_session
      event_type: "delete-history"
      company: $company
    )
  }
`;

const GENERATE_TABLE_CHART = gql`
  query MyQuery($chart_types: String!, $html_string: String!) {
    aivolvex_control_plane(
      event_type: "chart"
      html_string: $html_string
      chart_types: $chart_types
    )
  }
`;

const USER_CONTROLLER = gql`
  query MyQuery($company: String!, $user_id: String!) {
    aivolvex_control_plane(
      event_type: "offering"
      company: $company
      user_id: $user_id
    )
  }
`;
const EMBEDDING_FILE = gql`
  query MyQuery(
    $company: String!
    $temp_session_id: String!
    $unique_id: String!
    $avatar_id: String!
    $user_id: String!
  ) {
    aivolvex_control_plane(
      event_type: "embedding"
      company: $company
      temp_session_id: $temp_session_id
      unique_id: $unique_id
      avatar_id: $avatar_id
      user_id: $user_id
    )
  }
`;

const EMBEDDING_FILE_CHECK = gql`
  query MyQuery(
    $company: String!
    $temp_session_id: String!
    $unique_id: String!
  ) {
    aivolvex_control_plane(
      event_type: "embedding_details"
      company: $company
      temp_session_id: $temp_session_id
      unique_id: $unique_id
    )
  }
`;
const GET_PAGEINATION = gql`
  query MyQuery(
    $user_id: String!
    $company: String!
    $text: String!
    $dropdown: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      company: $company
      text: $text
      dropdown: $dropdown
      event_type: "pageno_recent"
    )
  }
`;
const GET_RECENT_PROMPTS = gql`
  query MyQuery(
    $user_id: String!
    $company: String!
    $page: String!
    $limit: String!
    $text: String!
    $dropdown: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      company: $company
      page: $page
      limit: $limit
      text: $text
      dropdown: $dropdown
      event_type: "recent_prompt"
    )
  }
`;
const GET_PAGEINATION_HISTORY = gql`
  query MyQuery($user_id: String!, $company: String!, $text: String!) {
    aivolvex_control_plane(
      user_id: $user_id
      company: $company
      text: $text
      event_type: "pageno_history"
    )
  }
`;
const GET_HISTORY_LIST = gql`
  query MyQuery(
    $user_id: String!
    $company: String!
    $page: String!
    $text: String!
    $limit: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      company: $company
      page: $page
      limit: $limit
      text: $text
      event_type: "history_prompt"
    )
  }
`;

const AVATAR_PINNED = gql`
  query MyQuery(
    $avatar_id: String!
    $company: String!
    $user_id: String!
    $pin_status: String!
    $type: String!
  ) {
    aivolvex_control_plane(
      avatar_id: $avatar_id
      company: $company
      user_id: $user_id
      pin_status: $pin_status
      event_type: "pin_unpin"
      pin_type: $type
    )
  }
`;

const LIST_PINNED = gql`
  query MyQuery($company: String!, $user_id: String!, $type: String!) {
    aivolvex_control_plane(
      company: $company
      user_id: $user_id
      pin_type: $type
      event_type: "list_pinned_avatar"
      pin_type: $type
    )
  }
`;
const GET_CREDIT_MONTH = gql`
  query MyQuery(
    $user_id: String!
    $date_time: String!
    $user_type: String!
    $company: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      date_time: $date_time
      user_type: $user_type
      company: $company
      event_type: "avatar_wise_question_count"
    )
  }
`;

const GET_TOKEN_AGENT = gql`
  query MyQuery(
    $user_id: String!
    $date_time: String!
    $user_type: String!
    $company: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      date_time: $date_time
      user_type: $user_type
      company: $company
      event_type: "avatar_token_monthwise"
    )
  }
`;

const GET_MODAL_CHART = gql`
  query MyQuery(
    $user_id: String!
    $date_time: String!
    $user_type: String!
    $company: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      date_time: $date_time
      user_type: $user_type
      company: $company
      event_type: "model_wise_question_count"
    )
  }
`;

const TOKEN_CREDIT = gql`
  query MyQuery($company: String!, $year: String!) {
    aivolvex_control_plane(
      company: $company
      year: $year
      event_type: "month_wise_token"
    )
  }
`;

const TOKEN_CREDIT_UPDATE = gql`
  query MyQuery(
    $company: String!
    $year: String!
    $user_id: String!
    $existing_value: String!
    $changed_value: String!
    $month: String!
  ) {
    aivolvex_control_plane(
      company: $company
      year: $year
      user_id: $user_id
      existing_value: $existing_value
      changed_value: $changed_value
      month: $month
      event_type: "update_token"
    )
  }
`;
const TOKEN_LOG = gql`
  query MyQuery(
    $company: String!
    $year: String!
    $month: String!
    $user_id: String!
  ) {
    aivolvex_control_plane(
      company: $company
      year: $year
      month: $month
      user_id: $user_id
      event_type: "log_list"
    )
  }
`;
const AGENT_LIST = gql`
  query MyQuery(
    $company: String!
    $year: String!
    $month: String!
    $user_id: String!
  ) {
    aivolvex_control_plane(
      company: $company
      year: $year
      user_id: $user_id
      month: $month
      event_type: "avatar_token_wise"
    )
  }
`;

const AGENT_LIST_INSERT = gql`
  query MyQuery(
    $company: String!
    $year: String!
    $avatar_id: String!
    $user_id: String!
    $month: String!
    $avatar_token: String!
  ) {
    aivolvex_control_plane(
      company: $company
      year: $year
      avatar_id: $avatar_id
      user_id: $user_id
      month: $month
      avatar_token: $avatar_token
      event_type: "insert_avatar_token"
    )
  }
`;
const AGENT_LIST_UPDATE = gql`
  query MyQuery(
    $company: String!
    $year: String!
    $avatar_id: String!
    $user_id: String!
    $month: String!
    $avatar_token: String!
  ) {
    aivolvex_control_plane(
      company: $company
      year: $year
      avatar_id: $avatar_id
      user_id: $user_id
      month: $month
      avatar_token: $avatar_token
      event_type: "update_avatar_token"
    )
  }
`;
const AGENT_LOG = gql`
  query MyQuery(
    $company: String!
    $year: String!
    $avatar_id: String!
    $user_id: String!
    $month: String!
  ) {
    aivolvex_control_plane(
      company: $company
      year: $year
      avatar_id: $avatar_id
      user_id: $user_id
      month: $month
      event_type: "log_avatar_list"
    )
  }
`;
const AGENT_DELETE = gql`
  query MyQuery(
    $company: String!
    $year: String!
    $avatar_id: String!
    $user_id: String!
    $month: String!
  ) {
    aivolvex_control_plane(
      company: $company
      year: $year
      avatar_id: $avatar_id
      user_id: $user_id
      month: $month
      event_type: "delete_avatar_token"
    )
  }
`;

const GET_CREDIT_DAY = gql`
  query MyQuery(
    $user_id: String!
    $date_time: String!
    $user_type: String!
    $company: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      date_time: $date_time
      user_type: $user_type
      company: $company
      event_type: "daywise_avatar_token"
    )
  }
`;

const GET_PROMPT_DAY = gql`
  query MyQuery(
    $user_id: String!
    $date_time: String!
    $user_type: String!
    $company: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      date_time: $date_time
      user_type: $user_type
      company: $company
      event_type: "daywise_question_token"
    )
  }
`;

const GET_NO_OF_AGENTS = gql`
  query MyQuery(
    $user_id: String!
    $date_time: String!
    $user_type: String!
    $company: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      date_time: $date_time
      user_type: $user_type
      company: $company
      event_type: "avatar_count"
    )
  }
`;

const GET_ALLOCATED_TOKEN = gql`
  query MyQuery(
    $user_id: String!
    $year: String!
    $month: String!
    $company: String!
    $user_type: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      year: $year
      month: $month
      company: $company
      user_type: $user_type
      event_type: "allocated_tokens"
    )
  }
`;
const GET_TOKEN_REQUEST = gql`
  query MyQuery(
    $user_id: String!
    $year: String!
    $month: String!
    $company: String!
    $user_type: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      year: $year
      month: $month
      company: $company
      user_type: $user_type
      event_type: "current_month_tokens"
    )
  }
`;

const GET_TOTAL_PROMPTS = gql`
  query MyQuery(
    $user_id: String!
    $year: String!
    $month: String!
    $company: String!
    $user_type: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      year: $year
      month: $month
      company: $company
      user_type: $user_type
      event_type: "question_count"
    )
  }
`;
const GET_TOP_USER = gql`
  query MyQuery(
    $user_id: String!
    $year: String!
    $month: String!
    $company: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      year: $year
      month: $month
      company: $company
      event_type: "top_user_token"
    )
  }
`;

const GET_USAGE_YEAR = gql`
  query MyQuery(
    $user_id: String!
    $year: String!
    $company: String!
    $user_type: String!
  ) {
    aivolvex_control_plane(
      user_id: $user_id
      year: $year
      company: $company
      user_type: $user_type
      event_type: "year_total_count"
    )
  }
`;
const WELCOME_EMAIL = gql`
  query MyQuery($user_id: String!) {
    aivolvex_control_plane(user_id: $user_id, event_type: "welcome_email")
  }
`;

const S3_DOCUMENT_LIST = gql`
  query MyQuery(
    $avatar_id: String!
    $company_name: String!
    $email_id: String!
  ) {
    aivolvex_control_plane(
      event_type: "s3-details"
      company: $company_name
      avatar_id: $avatar_id
      email_id: $email_id
    )
  }
`;

const S3_FILE_UPLOAD_TEXTOPS = gql`
  query MyQuery(
    $avatar_id: String!
    $company: String!
    $user_session: String!
    $user_id: String!
  ) {
    aivolvex_control_plane(
      event_type: "file-upload"
      company: $company
      avatar_id: $avatar_id
      user_session: $user_session
      user_id: $user_id
    )
  }
`;

const S3_FILE_DELETE_TEXTOPS = gql`
  query MyQuery(
    $avatar_id: String!
    $company: String!
    $unique_id: String!
    $user_id: String!
  ) {
    aivolvex_control_plane(
      event_type: "file_delete"
      company: $company
      avatar_id: $avatar_id
      unique_id: $unique_id
      user_id: $user_id
    )
  }
`;
const PUBLIC_LIST_AVATAR = gql`
  query MyQuery($event_type: String!) {
    aivolvex_control_plane(event_type: $event_type)
  }
`;
const HISTORY_RECORD_LIST = gql`
  query MyQuery($event_type: String!, $avatar_id: String!) {
    aivolvex_control_plane(event_type: $event_type, avatar_id: $avatar_id)
  }
`;
const HISTORY_RECORD_VIEW_LIST = gql`
  query MyQuery($event_type: String!, $user_session: String!) {
    aivolvex_control_plane(event_type: $event_type, user_session: $user_session)
  }
`;

// Public Agent Dashboard Queries Starts
const PUBLIC_PROMPT_COUNT = gql`
  query MyQuery($avatar_id: String!, $month: String!) {
    aivolvex_control_plane(
      event_type: "chat_dashboard_prompt_count"
      avatar_id: $avatar_id
      month: $month
    )
  }
`;

const PUBLIC_CUSTOMER_COUNT = gql`
  query MyQuery($avatar_id: String!, $month: String!) {
    aivolvex_control_plane(
      event_type: "chat_dashboard_customer_count"
      avatar_id: $avatar_id
      month: $month
    )
  }
`;

const PUBLIC_MONTHLY_CUST_COUNT = gql`
  query MyQuery($avatar_id: String!, $month: String!) {
    aivolvex_control_plane(
      event_type: "chat_dashboard_monthly_customers"
      avatar_id: $avatar_id
      month: $month
    )
  }
`;

const PUBLIC_TODAY_CHAT_TYPE = gql`
  query MyQuery($avatar_id: String!) {
    aivolvex_control_plane(
      event_type: "chat_dashboard_today_type"
      avatar_id: $avatar_id
    )
  }
`;

const PUBLIC_SENTIMENT = gql`
  query MyQuery($avatar_id: String!, $month: String!) {
    aivolvex_control_plane(
      event_type: "chat_dashboard_sentiment"
      avatar_id: $avatar_id
      month: $month
    )
  }
`;

const PUBLIC_CHAT_TYPE = gql`
  query MyQuery($avatar_id: String!, $month: String!) {
    aivolvex_control_plane(
      event_type: "chat_dashboard_type"
      avatar_id: $avatar_id
      month: $month
    )
  }
`;

const PUBLIC_LEAD_OPPURTUNITY = gql`
  query MyQuery($avatar_id: String!, $month: String!) {
    aivolvex_control_plane(
      event_type: "chat_dashboard_lead_opportunity"
      avatar_id: $avatar_id
      month: $month
    )
  }
`;

const AVATAR_INFO_DETAILS = gql`
  query MyQuery($avatar_id: String!, $user_id: String!) {
    aivolvex_control_plane(
      user_id: $user_id
      event_type: "about_us_api"
      avatar_id: $avatar_id
    )
  }
`;
const PROFILE_INSERT = gql`
  query MyQuery($input_values: String!, $event_type: String!) {
    aivolvex_control_plane(event_type: $event_type, input_values: $input_values)
  }
`;

const PROFILE_LIST = gql`
  query MyQuery(
    $key_id: String!
    $event_type: String!
    $meta_key: String!
    $user_id: String!
  ) {
    aivolvex_control_plane(
      event_type: $event_type
      key_id: $key_id
      meta_key: $meta_key
      user_id: $user_id
    )
  }
`;

const GENERATEDQSEARCHURL = gql`
  query MyQuery {
    aivolvex_control_plane(event_type: "quicksight_url")
  }
`;
const HISTORY_PDF_LINK = gql`
  query MyQuery($user_session: String!, $company: String!) {
    aivolvex_control_plane(
      event_type: "get_pdf_history"
      user_session: $user_session
      company: $company
    )
  }
`;
// Public Agent Dashboard Queries Ends
const GET_CHAT_RECORDS = gql`
  query MyQuery($avatar_id: String!) {
    aivolvex_control_plane(
      event_type: "public-agent-history"
      avatar_id: $avatar_id
    )
  }
`;

const GET_CHAT_TRANSCRIPT = gql`
  query MyQuery($user_session: String!) {
    aivolvex_control_plane(
      event_type: "chat_transcript"
      user_session: $user_session
    )
  }
`;

const GET_CHAT_AVATAR_DETAILS = gql`
  query MyQuery {
    aivolvex_control_plane(event_type: "avatar-details")
  }
`;

const GET_CHAT_POD_DETAILS = gql`
  query MyQuery($id: String!) {
    aivolvex_control_plane(event_type: "chat_pod_details", avatar_id: $id)
  }
`;

const GET_CALL_TABLE = gql`
  query MyQuery {
    aivolvex_control_plane(event_type: "diarize_summary_details")
  }
`;
const GET_FINANCE_CALL_TABLE = gql`
  query MyQuery {
    aivolvex_control_plane(event_type: "diarize_summary_details_new")
  }
`;

const GET_CALL_TRANSCRIPT = gql`
  query MyQuery($user_session: String!) {
    aivolvex_control_plane(
      event_type: "get-conversation"
      user_session: $user_session
    )
  }
`;

const GET_CALL_SENTIMENT_CHART = gql`
  query MyQuery($user_session: String!) {
    aivolvex_control_plane(
      event_type: "get-voice-chart"
      user_session: $user_session
    )
  }
`;

const TRIGGER_VOICE_ANALYSIS = gql`
  query MyQuery($key: String!) {
    voice_trigger(key: $key)
  }
`;

const ADD_FILE_UPLOAD = gql`
  mutation MyMutation($input: UploadFilesInput!) {
    multi_file_upload(input: $input)
  }
`;

const GET_SECRETS = gql`
  query MyQuery($event_type: String!) {
    key_get_upload(event_type: $event_type)
  }
`;

// query MyQuery {
//   key_get_upload(event_type: "largefile_insert")
// }

export {
  TRIGGER_VOICE_ANALYSIS,
  GET_CALL_SENTIMENT_CHART,
  GET_CALL_TRANSCRIPT,
  GET_CALL_TABLE,
  GET_CHAT_POD_DETAILS,
  GET_CHAT_AVATAR_DETAILS,
  GET_CHAT_TRANSCRIPT,
  GET_CHAT_RECORDS,
  ONBOARD_AVATAR,
  LIST_AVATAR,
  GET_AVATAR_DETAILS,
  GET_START_CONVERSATION,
  UPDATE_AVATAR,
  LOGIN_EMAIL_CHECK,
  GET_START_CONVERSATION_CLOUDSHIFU,
  AZUREADEMAILCHECK,
  GET_START_CONVERSATION_KENDRA,
  AVATARFREQUENTLYPROMPTS,
  AVATARHISTORYTITLE,
  AVATARCHATHISTORY,
  CUSTOMERDETAILS,
  GETTOKENREMAINING,
  KENDRA_OPENAI,
  GET_START_CONVERSATION_PG_VECTOR,
  GET_START_CONVERSATION_GENERAL_CHAT,
  AVATAR_TAG_MEMBERS_LIST,
  SHARE_NEW_AVATRA,
  INSERT_NEW_FEEDBACK,
  GET_AVATAR_LIBRARY_LIST,
  GET_TAG_LIST,
  INSERT_NEW_TAG,
  UPDATE_TAG,
  DELETE_TAG,
  GET_AVALATAR_LIST,
  HISTORY_EDIT,
  HISTORY_DELETE,
  GENERATE_TABLE_CHART,
  USER_CONTROLLER,
  EMBEDDING_FILE,
  EMBEDDING_FILE_CHECK,
  GET_PAGEINATION,
  GET_RECENT_PROMPTS,
  GET_PAGEINATION_HISTORY,
  GET_HISTORY_LIST,
  AVATAR_PINNED,
  LIST_PINNED,
  GET_CREDIT_MONTH,
  GET_TOKEN_AGENT,
  GET_MODAL_CHART,
  TOKEN_CREDIT,
  TOKEN_CREDIT_UPDATE,
  TOKEN_LOG,
  AGENT_LIST,
  AGENT_LIST_INSERT,
  AGENT_LOG,
  AGENT_LIST_UPDATE,
  GET_CREDIT_DAY,
  GET_PROMPT_DAY,
  GET_NO_OF_AGENTS,
  GET_ALLOCATED_TOKEN,
  GET_TOKEN_REQUEST,
  GET_TOTAL_PROMPTS,
  GET_TOP_USER,
  GET_USAGE_YEAR,
  AGENT_DELETE,
  WELCOME_EMAIL,
  S3_DOCUMENT_LIST,
  PUBLIC_LIST_AVATAR,
  HISTORY_RECORD_LIST,
  HISTORY_RECORD_VIEW_LIST,
  PUBLIC_PROMPT_COUNT,
  PUBLIC_CHAT_TYPE,
  PUBLIC_CUSTOMER_COUNT,
  PUBLIC_LEAD_OPPURTUNITY,
  PUBLIC_MONTHLY_CUST_COUNT,
  PUBLIC_SENTIMENT,
  S3_FILE_UPLOAD_TEXTOPS,
  S3_FILE_DELETE_TEXTOPS,
  PUBLIC_TODAY_CHAT_TYPE,
  AVATAR_INFO_DETAILS,
  PROFILE_INSERT,
  PROFILE_LIST,
  GENERATEDQSEARCHURL,
  HISTORY_PDF_LINK,
  GET_FINANCE_CALL_TABLE,
  ADD_FILE_UPLOAD,
  GET_SECRETS,
};
