import { Col, Row } from "antd";
import SidemenuMobile from "../../Sidemenu/SidemenuMobile";
import Navbarmobile from "../../Header/Mobile/Navbarmobile";
import Callmainboardmobile from "./Callmainbodymobile";
import { useDispatch, useSelector } from "react-redux";
const CallMobileboard = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Navbarmobile />
        </Col>
      </Row>
      <Row className="dashboard-mobile-body">
        <Col span={24}>
          <Callmainboardmobile />
        </Col>
      </Row>
      <Row className="chat-bottom-section">
        <Col span={24}>
          <SidemenuMobile />
        </Col>
      </Row>
    </>
  );
};
export default CallMobileboard;
