import { memo, useEffect, useState } from "react";
import "./Myprofile.scss";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import sidemenulogo from "../../assets/sidemenuicon.png";
import { IoShuffleOutline } from "react-icons/io5";
import { IoIosKeypad, IoIosTimer } from "react-icons/io";
import aivolvex from "../../assets/ui/X Icon SVG.svg";

const Sidemenu = (props) => {
  const { offerstatus } = useSelector((state) => state.chat);
  const sidebarmenu = props.sidebarmenu;
  const [isExpanded, setIsExpanded] = useState(true);
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);
  const handleAgentClick = () => {
    setSubMenuVisible(!isSubMenuVisible);
  };

  return (
    <>
      {/* New Desktop side bar */}
      <Sidebar
        // defaultCollapsed={true}
        // collapsed={!isExpanded}
        className="costimize-sidebar sidebar-desktop"
      >
        <Menu>
          <MenuItem
            className={sidebarmenu === 1 ? "active active-menu-item" : ""}
            // onClick={handleAgentClick}
            component={<Link to="/calls" />}
          >
            <div className="nav-icon-sidebar">
              <Icon
                icon="ic:sharp-call"
                id="library"
                className={`DashboardNavicon ${
                  sidebarmenu === 1 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 1 ? "pink-icon" : ""
              }`}
            >
              Calls
            </div>
          </MenuItem>

          <MenuItem
            className={sidebarmenu === 2 ? "active active-menu-item" : ""}
            component={<Link to="/chats" />}
          >
            <div className="nav-icon-sidebar">
              <Icon
                icon="ph:chats-light"
                id="library"
                className={`DashboardNavicon ${
                  sidebarmenu === 2 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 2 ? "pink-icon" : ""
              }`}
            >
              Chats
            </div>
          </MenuItem>

          <MenuItem
            className={sidebarmenu === 3 ? "active active-menu-item" : ""}
            component={<Link to="/finance" />}
          >
            <div className="nav-icon-sidebar">
              <Icon
                icon="ph:phone-call-thin"
                id="library"
                className={`DashboardNavicon ${
                  sidebarmenu === 3 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 3 ? "pink-icon" : ""
              }`}
            >
              Finance
            </div>
          </MenuItem>

          <MenuItem
            className={sidebarmenu === 4 ? "active active-menu-item" : ""}
            component={<Link to="/audit" />}
          >
            <div className="nav-icon-sidebar">
              <Icon
                icon="ant-design:audit-outlined"
                id="library"
                className={`DashboardNavicon ${
                  sidebarmenu === 4 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`label-sidemenu ${
                sidebarmenu === 4 ? "pink-icon" : ""
              }`}
            >
              Audit
            </div>
          </MenuItem>
        </Menu>
      </Sidebar>
    </>
  );
};
export default Sidemenu;
