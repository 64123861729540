import { useEffect, useState } from "react";
import { Col, Row, Button, Drawer } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import Privatepodlist from "../Desktop/Privatepodlist";
import Callmainmobilebody from "./Callmainmobilebody";
import { useParams } from "react-router-dom";
import { getpinnedavatar } from "../../Conversation/Auth/ConversationAuth";
const Callmainboardmobile = () => {
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const { remainingcredits, avatardetails, pinnedstatus } = useSelector(
    (state) => state.chat
  );
  const [draweropen, setdrawerOpen] = useState(false);
  const onClose = () => {
    setdrawerOpen(false);
  };
  useEffect(() => {
    if (id) {
      setdrawerOpen(false);
    }
  }, [id]);
  const setPinnedAvatar = () => {
    var pin_status = "pin";
    var pinmessage = "Agent Pinned";
    if (avatardetails.pinned_status === "pinned") {
      pin_status = "unpin";
      pinmessage = "Agent Unpinned";
    }

    const input = {
      avatar_id: id,
      company: companyName,
      user_id: userName,
      pin_status: pin_status,
      type: "public",
    };
    getpinnedavatar(dispatch, input, pinnedstatus, pinmessage);
  };
  return (
    <>
      <Row className="chat-header-sub-part">
        <Col className="chat-icon-button d-flex align-items-center" span={2}>
          <Button
            size="large"
            className="chat-cancel-icon"
            onClick={() => setdrawerOpen(!draweropen)}
          >
            {draweropen ? (
              <Icon icon="octicon:x-16" className="chat-icon-inner-button" />
            ) : (
              <Icon
                icon="ph:dots-three-bold"
                className="chat-icon-inner-button"
              />
            )}
          </Button>
        </Col>
        <Col className="chat-header-mobile-text" span={22}>
          {avatardetails ? (
            <>
              <div className="chat-agent-name pt-1  d-flex align-items-center  justify-content-center">
                {avatardetails.avatar_name}
                <div>
                  {id ? (
                    <>
                      {" "}
                      <Icon
                        onClick={() => {
                          setPinnedAvatar();
                        }}
                        icon="fluent-mdl2:pinned"
                        className={
                          avatardetails.pinned_status === "pinned"
                            ? "volvex-chat-header-icon cursor-pointer pinned-color"
                            : "volvex-chat-header-icon cursor-pointer"
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {/* <div className="chat-agent-name d-flex justify-content-center"><br /> <br /></div> */}
            </>
          )}
          {remainingcredits ? (
            <div className="chat-agent-sub-name">
              {/* AIVolvex Credits : {remainingcredits} */}
            </div>
          ) : (
            <>{/* <div className="chat-agent-sub-name "><br /></div> */}</>
          )}
        </Col>
      </Row>
      <Row className="recent-mobile-body mobile-background">
        <Col span={24}>
          {id && (
            <>
              <Callmainmobilebody />
            </>
          )}
        </Col>
      </Row>
      <div className="chat-mobile-pod-drawer">
        <Drawer
          className="chat-mobile-pod-drawer"
          placement="left"
          onClose={onClose}
          open={draweropen}
        >
          <Privatepodlist />
        </Drawer>
      </div>
    </>
  );
};
export default Callmainboardmobile;
