import { memo, useEffect, useState } from "react";
import { Col, Row, Button, Drawer, Input, Tooltip, Dropdown } from "antd";
import CallDesktopBoard from "./Desktop/CallDesktopBoard";
import CallMobileboard from "./Mobile/CallMobileboard";
const CallsBody = () => {
  return (
    <>
      <div className="conversation-desktop">
        <CallDesktopBoard />
      </div>
      <div className="conversation-mobile">
        <CallMobileboard />
      </div>
    </>
  );
};
export default CallsBody;
