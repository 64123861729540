import { Col, Row } from "antd";
import NavBar from "../../Header/NavBar";
import Sidemenu from "../../Sidemenu/Sidemenu";
import Privatepodlist from "./Privatepodlist";
import Callmainbody from "./Callmainbody";
import { useParams } from "react-router-dom";
const CallDesktopBoard = () => {
  const { id } = useParams();

  return (
    <>
      <NavBar />
      <div className="body-bg">
        <Row className="desktop-conversaction-body">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={2}
            xl={2}
            className="body-margin-top body-margin-bottom-mobile"
          >
            <Sidemenu />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={22}
            xl={22}
            className="dashboard-board-background"
          >
            <Callmainbody />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default CallDesktopBoard;
