import CallDesktopBoard from "./CallDesktopBoard";
const CallsBodyV1 = () => {
  return (
    <>
      <div className="conversation-desktop">
        <CallDesktopBoard />
      </div>
    </>
  );
};
export default CallsBodyV1;
